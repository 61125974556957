/* eslint-disable import/no-anonymous-default-export */
import getRequestConfig from 'ReduxToolkit/functions/getRequestConfig';
import axios from 'axios';

//GET
const getWorkspace = async (jwt: string, URL: string, id: string) => {
  const requestConfig = getRequestConfig.basic(jwt);

  const response = await axios.get(`${URL}/Spaces/${id}`, requestConfig);
  return response.data;
};

const getWorkspaces = async (jwt: string, URL: string) => {
  const requestConfig = getRequestConfig.basic(jwt);

  const response = await axios.get(`${URL}/Spaces`, requestConfig);
  return response.data;
};

//POST
const postWorkspacePhoto = async (
  jwt: string,
  URL: string,
  id: string,
  photo: File
) => {
  const requestConfig = getRequestConfig.contentTypeJson(jwt);

  const fd = new FormData();

  fd.append('photo', photo, photo.name);

  const response = await axios.post(
    `${URL}/Spaces/${id}/setphoto`,
    fd,
    requestConfig
  );
  return response.data;
};

const postWorkspace = async (jwt: string, URL: string, newWorkspace: string) => {
  const requestConfig = getRequestConfig.contentTypeJson(jwt);

  const response = await axios.post(`${URL}/Spaces`, newWorkspace, requestConfig);
  return response.data;
};

//PUT
const updateWorkspace = async (jwt: string, URL: string, data: any) => {
  const requestConfig = getRequestConfig.contentTypeJson(jwt);
  const dataJSON = JSON.stringify(data);

  const response = await axios.put(`${URL}/Spaces`, dataJSON, requestConfig);
  return response.data;
};

const putWorkspaceDomain = async (
  jwt: string,
  URL: string,
  data: any,
  id: string
) => {
  const requestConfig = getRequestConfig.contentTypeJson(jwt);
  const dataJSON = JSON.stringify(data);

  const response = await axios.put(
    `${URL}/Spaces/${id}/customDomain`,
    dataJSON,
    requestConfig
  );
  return response.data;
};

//DEL
const deleteWorkspace = async (jwt: string, URL: string, id: string) => {
  const requestConfig = getRequestConfig.contentTypeJson(jwt);

  const response = await axios.delete(`${URL}/Spaces/${id}`, requestConfig);
  return response.data;
};

const deleteCustomDomain = async (jwt: string, URL: string, id: string) => {
  const requestConfig = getRequestConfig.contentTypeJson(jwt);

  const response = await axios.delete(
    `${URL}/Spaces/${id}/customDomain`,
    requestConfig
  );
  return response.data;
};

export default {
  getWorkspace,
  getWorkspaces,
  postWorkspace,
  deleteWorkspace,
  putWorkspaceDomain,
  updateWorkspace,
  postWorkspacePhoto,
  deleteCustomDomain,
};
