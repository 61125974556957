import axios from 'axios';
// Utils
import getRequestConfig from 'ReduxToolkit/functions/getRequestConfig';
// Types
import { DateData } from './types/DateData';
import { AuditLog } from '@trii/types/dist/AuditLogs';

const getLogs = async (
  URL: string,
  jwt: string,
  dateData: DateData
): Promise<AuditLog[]> => {
  const { start, end, type } = dateData;
  const config = getRequestConfig.basic(jwt);

  const response = await axios.get<AuditLog[]>(
    `${URL}/AuditLogs?start=${start}&end=${end}&type=${type}`,
    config
  );

  return response.data;
};

const auditLogService = { getLogs };

export default auditLogService;
