import { useState, useContext, useEffect } from 'react';
// Context
import { StyleContext } from 'style/styleProvider';
import { subscriptionContext } from 'features/Subscription/context/SubscriptionProvider';
import {
  TabNavbar,
  MySubscriptionsTab,
} from './components';
// Components/ui
import { Box, Container } from '@mui/material';

const SubscriptionContainer = () => {
  const [value, setValue] = useState(0);
  const { color, shadow } = useContext(StyleContext);
  const { getSubscriptions } = useContext(subscriptionContext);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getSubscriptions();
  }, [])

  return (
    <Container maxWidth="md">
      <Box
        display={'flex'}
        flexDirection={'column'}
        height={'100%'}
        width={'100%'}
        boxShadow={shadow.base}
        bgcolor={color.gray[100]}
        className={'fadeinup'}
        borderRadius={4}
      >
        <TabNavbar value={value} handleChange={handleChange} />
        <MySubscriptionsTab value={value} />
        {/* <AddressesTab value={value} /> */}
        {/* <InvoicesTab value={value} /> */}
      </Box>
    </Container>
  );
}

export default SubscriptionContainer;
