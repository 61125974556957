import { createSlice } from '@reduxjs/toolkit';
import { CognitoUserPool, CookieStorage } from 'amazon-cognito-identity-js';
// Types
import { RootState } from '../../rootReducer';

console.log('CookieStorage. 2');

const initialState = {
  pool: new CognitoUserPool({
    UserPoolId: 'sa-east-1_81dIzrFFU',
    ClientId: '12rg9i8fotah5dt9ms8mfmn2mq',
    Storage: new CookieStorage({
      secure: true,
      sameSite: 'none',
      domain: window.location.host.split(':')[0].replace('account.', ''),
    }),
  }),
};

const poolSlice = createSlice({
  name: 'pool',
  initialState,
  reducers: {},
});

export const selectPool = (state: RootState) => state.Pool.pool;

export default poolSlice.reducer;
