import {
  useState,
  useEffect,
  useContext,
  useMemo,
} from 'react';
import { DataContext } from '../context/contextApi';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
// Redux
import { useSelector } from 'react-redux';
import { selectInvoiceById } from 'ReduxToolkit/features/invoiceSlice/invoiceSlice';
// Components/ui
import './EnlacesLogin.css';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';

toast.configure();

interface Option {
  path: string;
  text: string;
}

const options: Option[] = [
  { path: 'auditlog', text: 'navBar.auditLogs' },
  { path: 'security', text: 'global.security' },
  { path: 'account', text: 'global.myAccount' },
  { path: 'subscribe', text: 'navBar.subscriptions' },
  { path: 'invoices', text: 'subscription.invoices' },
  { path: '', text: 'navBar.workspaces' },
];

const EnlacesLogin = ({ authenticated }) => {
  const { t } = useTranslation();
  const {  isMobile } = useContext(DataContext);
  let select = JSON.parse(localStorage.getItem('select'));
  const [windowPath, setWindowPath] = useState(
    window.location.pathname.replace(/\//, '')
  );
  const location = useLocation();
  let newLocation = location.pathname.replace(/\//, '');
  const str = location.pathname;
  const workSpacesStr = str.includes('workspaces');
  const [value, setValue] = useState(select ? select : '');
  // Invoices Pay
  const isInvoicesPay = /^invoices\/[a-zA-Z0-9]+$/.test(windowPath)
  const invoiceId = isInvoicesPay? extractIdFromUrl(window.location.href) : null
  const invoice = useSelector(selectInvoiceById(invoiceId)) 
  
  const Title = getTitle()

  function getTitle(): string | null {
    for (const option of options) {
      if (windowPath === option.path) {
        return t(option.text);
      }
    }
  
    if (windowPath && workSpacesStr) {
      return t('navBar.workspaces');
    }
  
    if (isInvoicesPay && invoice) {
      return `${t('invoices.pay')} ${invoice.invoiceNumber}`;
    }
  
    return null;
  }

  function extractIdFromUrl(url: string): string | null {
    try {
      const urlObject = new URL(url);
      const segments = urlObject.pathname.split('/');
      const id = segments[segments.length - 1];
      return id || null;
    } catch (error) {
      return null;
    }
  }

  useEffect(() => {
    setWindowPath(newLocation);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useMemo(() => {
    if (select === null) {
      setValue('espanol');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      {authenticated ? (
        <>
          {!isMobile && (
            <span className="h4 m-0">
              {Title}
            </span>
          )}
        </>
      ) : (
        <div>
          <NavLink to={""}>
            <button color="inherit">{t('global.login')}</button>
          </NavLink>
        </div>
      )}
    </>
  );
};

export default EnlacesLogin;
