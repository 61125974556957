import { useEffect, useState, useContext } from 'react';
// @ts-ignore
import moment from 'moment';
// @ts-ignore
import dayjs, { Dayjs } from 'dayjs';
// Translation
import { useTranslation } from 'react-i18next';
// Context
import { DataContext } from 'context/contextApi';
// Utils
import { logTypes } from './utils/logTypes';
//Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  selectLogs,
  fetchLogs,
  setLogDateFilter,
  logsTypeFilterSelector,
  setLogTypeFilter,
  getLogsStatus,
} from 'ReduxToolkit/features/auditLogSlice/auditLogSlice';
// Components/ui
import './AuditLog.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {
  Alert,
  Button,
  Snackbar,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Chip,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { toast } from 'react-toastify';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-toastify/dist/ReactToastify.css';
import { menuProps, getMobileStyle } from './style';
import { AuditLogType } from '@trii/types/dist/AuditLogs';

function getStyles(name, typeValue, theme) {
  return {
    fontSize: 12,
    fontWeight:
      typeValue.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

toast.configure();

const AuditLog = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { isMobile, showPageSideBar } = useContext(DataContext);
  const mobileStyle = getMobileStyle(isMobile);
  const logs = useSelector(selectLogs);
  const logStatus = useSelector(getLogsStatus);
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const [typeValue, setTypeValue] = useState([]);
  const DATE_FORMAT = 'DD/MM/YYYY HH:mm';
  const todayDate: Dayjs = dayjs();
  const [selectedStartDate, setSelectedStartDate] = useState(todayDate);
  const [selectedEndDate, setSelectedEndDate] = useState(todayDate);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState('');
  const [closeAlert, setCloseAlert] = useState(false);

  const handleChangeFilterType = (event) => {
    const newTypeValue = event.target.value;

    dispatch(setLogTypeFilter(newTypeValue));

    const {
      target: { value },
    } = event;

    setTypeValue(typeof value === 'string' ? value.split(',') : value);
  };

  const applyFilter = async () => {
    dispatch(
      setLogDateFilter({
        start: selectedStartDate.unix(),
        end: selectedEndDate.unix(),
      })
    );
    dispatch(setLogTypeFilter(typeValue));
    dispatch(
      fetchLogs({
        start: selectedStartDate.unix(),
        end: selectedEndDate.unix(),
        type: typeValue.join(''),
      })
    );
  };

  const handleCloseAlert = () => {
    setCloseAlert(true);
  };

  const handleStartDateChange = (newStartDate: Dayjs) => {
    setSelectedStartDate(newStartDate);
  };

  const handleEndDateChange = (newEndDate: Dayjs) => {
    setSelectedEndDate(newEndDate);
  };

  useEffect(() => {
    logStatus === 'rejected' && toast.error('No se pudo acceder a los registros');
  }, [logStatus]); //eslint-disable-line

  useEffect(() => {
    if (
      selectedStartDate.isAfter(selectedEndDate) &&
      !selectedStartDate.isSame(selectedEndDate, 'minute')
    ) {
      setError(t('auditLogView.errorDate'));
      setCloseAlert(false);
      setIsError(true);
    } else {
      setIsError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStartDate, selectedEndDate]);

  return (
    <>
      <Box
        className="overflow-auto api-custom-scroll"
        sx={{
          maxHeight: 'calc(100vh - 120px)',
          paddingRight: isMd ? '10px' : '0px',
        }}
      >
        <Box sx={{ marginTop: 0, minWidth: '1024px', paddingBottom: 'calc(100vh - 350px)' }}>
          <Box id="AccountProfileInfoPanel" className="panel">
            <Box
              className={!isMd ? 'panel-hdr panel-hdr-xs' : 'panel-hdr'}
              sx={{ justifyContent: 'space-between', paddingY: '5px' }}
            >
              <Box
                className={
                  !isMd
                    ? 'd-flex align-items-center pl-0'
                    : 'd-flex align-items-center'
                }
                sx={{ flexDirection: mobileStyle.flexColumn }}
              >
                {/* Datepicker */}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      format={DATE_FORMAT}
                      ampm={false}
                      slotProps={{
                        textField: () => ({
                          label: t('auditLogView.from'),
                          size: 'small',
                          className: 'smallInput',
                          sx: {
                            borderColor: isError
                              ? theme.palette.error.main
                              : // @ts-ignore
                                theme.palette.Boxider,
                          },
                          inputProps: {
                            shrink: 'true',
                          },
                        }),
                      }}
                      value={selectedStartDate}
                      onChange={handleStartDateChange}
                    />
                    <Typography
                      variant="body1"
                      sx={{
                        marginX: '8px',
                        marginTop: '-15px',
                        color: 'text.primary',
                      }}
                    >
                      _
                    </Typography>
                    <DateTimePicker
                      format={DATE_FORMAT}
                      ampm={false}
                      slotProps={{
                        textField: () => ({
                          label: t('auditLogView.to'),
                          size: 'small',
                          className: 'smallInput',
                          width: '50%',
                        }),
                      }}
                      value={selectedEndDate}
                      onChange={handleEndDateChange}
                    />
                  </LocalizationProvider>
                </Box>
                {/* Tipos */}
                <Box sx={{ marginLeft: !isMd ? '0px' : '15px' }}>
                  <FormControl
                    sx={{
                      m: 1,
                      minWidth: isMobile
                        ? showPageSideBar
                          ? 'calc(100vw - 150px)'
                          : 'calc(100vw - 108px)'
                        : 200,
                      maxWidth: 300,
                    }}
                    size="small"
                    className="fadein"
                  >
                    <InputLabel id="demo-multiple-chip-label" shrink>
                      {t('global.type')}
                    </InputLabel>
                    <Select
                      id="demo-multiple-chip"
                      multiple
                      value={typeValue}
                      onChange={handleChangeFilterType}
                      input={
                        <OutlinedInput id="select-multiple-chip" label="Tipo" />
                      }
                      size="small"
                      sx={{
                        '& legend': {
                          maxWidth: 'fit-content',
                        },
                        '& .MuiOutlinedInput-input': {
                          paddingTop: '4px',
                          paddingBottom: '4px',
                        },
                      }}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip
                              key={value}
                              label={
                                value === 1
                                  ? 'Info'
                                  : value === 2
                                  ? 'Warning'
                                  : value === 3
                                  ? 'Error'
                                  : null
                              }
                              sx={{ fontSize: 12, height: 20, marginTop: 0.2 }}
                            />
                          ))}
                        </Box>
                      )}
                      MenuProps={menuProps}
                    >
                      {logTypes.map((value, index) => (
                        <MenuItem
                          key={index}
                          value={value.type}
                          sx={getStyles(value, typeValue, theme)}
                        >
                          {value.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              <Button
                variant="contained"
                sx={{ marginRight: '16px' }}
                onClick={applyFilter}
                disabled={isError}
              >
                {t('filter.apply')}
              </Button>
            </Box>
          </Box>
          <Snackbar
            open={isError && !closeAlert}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <Alert
              onClose={handleCloseAlert}
              severity="error"
              sx={{
                width: '100%',
              }}
              variant="filled"
            >
              {error}
            </Alert>
          </Snackbar>
          <TableContainer
            component={Paper}
            sx={{
              maxHeight: isMd ? 'calc(100vh - 250px)' : 'calc(100vh - 340px)',
            }}
          >
            <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ minWidth: '110px' }}
                    style={{ backgroundColor: 'lightgray' }}
                  ></TableCell>
                  <TableCell
                    sx={{ backgroundColor: 'lightgray' }}
                    style={{ minWidth: '200px' }}
                    size={'small'}
                  >
                    {t('tableHeader.dateTime')}
                  </TableCell>
                  <TableCell sx={{ backgroundColor: 'lightgray' }}>
                    {t('global.detail')}
                  </TableCell>
                </TableRow>
              </TableHead>

              {logStatus === 'rejected' && logs && (
                <>
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={3} sx={{ width: '100%' }}>
                        <Typography sx={{ textAlign: 'center' }}>
                          {t('tableContent.noLogs')}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </>
              )}
              {logStatus === 'succeeded' && logs.length === 0 && (
                <>
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={3} sx={{ width: '100%' }}>
                        <Typography sx={{ textAlign: 'center' }}>
                          {t('tableContent.noLogs')}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </>
              )}
              {logStatus === 'loading' && logs && logs.length === 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ width: '110px' }}>
                      <Skeleton variant="rectangular"></Skeleton>
                    </TableCell>
                    <TableCell sx={{ width: '200px' }}>
                      {' '}
                      <Skeleton variant="rectangular"></Skeleton>
                    </TableCell>
                    <TableCell sx={{ width: '100%' }}>
                      {' '}
                      <Skeleton variant="rectangular"></Skeleton>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}

              <TableBody>
                {logs &&
                  logStatus === 'succeeded' &&
                  logs.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{
                        '&:last-child td, &:last-child th': {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell sx={{ width: '110px' }}>
                        <Typography
                          sx={{
                            color:
                              row.type === AuditLogType.INFO
                                ? 'green'
                                : row.type === AuditLogType.WARNING
                                ? 'orange'
                                : row.type === AuditLogType.ERROR
                                ? 'red'
                                : 'white',
                            fontSize: '0.8rem',
                          }}
                        >
                          {row.type === AuditLogType.INFO
                            ? 'Info'
                            : row.type === AuditLogType.WARNING
                            ? 'Warning'
                            : row.type === AuditLogType.ERROR
                            ? 'Error'
                            : 'error data type'}
                        </Typography>
                      </TableCell>
                      <TableCell scope="row" sx={{ minWidth: '200px' }}>
                        {moment(row.datetime).format('L, h:mm a')}
                      </TableCell>
                      <TableCell sx={{ width: '100%' }}>{row.details}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
};

export default AuditLog;
