/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios';


const getSubdomainAvailability = async (
  jwt: string,
  URL: string,
  subdomainJson: string,
  ) => {
  const response = await axios.post(`${URL}/domains/Check`, subdomainJson);

  return response.data;
};

export default { getSubdomainAvailability };
