import { useContext } from 'react';
// Types
import { Spaces } from '@trii/types';
// Translation
import { useTranslation } from 'react-i18next';
// Context
import { StyleContext } from 'style/styleProvider';
// Components/ui
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

interface DataCenterSelectProps {
  regionSelect: string;
  setRegionSelect: (value: string) => void;
}

const DataCenterSelect = ({
  regionSelect,
  setRegionSelect,
}: DataCenterSelectProps) => {
  const { fontSize } = useContext(StyleContext);
  const { t } = useTranslation();

  const SelectOptions = Spaces.RegionInfo.list.map((region) => (
    <MenuItem key={region.Value} value={region.Value}>
      <Typography fontSize={fontSize.md}>
        {t(`workSpaces.${region.Name}`)}
      </Typography>
    </MenuItem>
  ));

  const handleSelect = (event) => {
    setRegionSelect(event.target.value);
  };

  return (
    <Box minWidth={'100%'}>
      <FormControl sx={{width:'100%'}}>
      <InputLabel id="simple-select-label" shrink>
        {t('workSpaces.dataCenter')}
      </InputLabel>
        <Select
          labelId="simple-select-label"
          label={t('workSpaces.dataCenter')}
          value={regionSelect}
          onChange={handleSelect}
          variant="outlined"
          size="small"
          sx={{
            '& legend': {
              maxWidth: 'fit-content',
              // padding: '0 4px',
            },
            // '& fieldset': { top: 0 },
            marginBottom: 10,
          }}
        >
          {SelectOptions}
        </Select>
      </FormControl>
    </Box>
  );
};

export default DataCenterSelect;
