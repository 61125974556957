import TabPanel from '../../../components/TabPanel';
import SubscriptionList from './components/SubscriptionList';

interface MySubscriptionsTabProps {
  value: number;
}

const MySubscriptionsTab = ({ value }: MySubscriptionsTabProps) => {
  return (
    <TabPanel value={value} index={0}>
      <SubscriptionList />
    </TabPanel>
  );
}

export default MySubscriptionsTab;