import  { useContext, useEffect } from 'react';
// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import {  useSelector } from 'react-redux';
import { getInvoices, invoiceListSelector } from 'ReduxToolkit/features/invoiceSlice/invoiceSlice';
// Components/ui
import { Box } from '@mui/material';
import { StyleContext } from 'style/styleProvider';
import { Payment } from 'components';

export default function Body() {
  const { mediaQuery } = useContext(StyleContext);
  const dispatch = useAppDispatch();
  const invoices = useSelector(invoiceListSelector);
  const maxHeight = mediaQuery.md ? '56vh' : mediaQuery.lg ? '58vh' : '63vh';

  useEffect(() => {
      dispatch(getInvoices());
  }, [dispatch]);

  return (
    <Box
      height={'80%'}
      display={'flex'}
      maxHeight={maxHeight}
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box maxHeight="90%" height="90%" overflow="auto" pb={1}>
        {invoices.map((invoice) => (
          <Payment key={invoice.id} data={invoice} />
        ))}
      </Box>
    </Box>
  )
}
