import { useState } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
import {
  Box,
  Typography,
} from '@mui/material';
import { mokPlans } from 'features/Plans/utils/mokPlans';
// Components
import {
  CardPlan,
  ModalUpgrade,
  PlanDuration,
} from './components';

const PlanType = () => {
  const { t } = useTranslation();
  const actualPlan = mokPlans[0] //basic plan
  const newPlan = mokPlans[1] //pro plan
  const [pricingOption, setPricingOption] = useState('monthly');
  const [openModalUpgrade, setOpenModalUpgrade] = useState(false);

  const handleClick = (
    event: React.SyntheticEvent,
    newPricingOption: string
  ) => {
    if (newPricingOption !== null) {
      setPricingOption(newPricingOption);
    }
  };
  const handleOpenModalUpgrade = () => {
    setOpenModalUpgrade(true);

  }
  const handleCloseModalUpgrade = () => setOpenModalUpgrade(false);
  
  const acceptNewPlan = () => {
    setOpenModalUpgrade(false);
  }

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 1rem',
        bgcolor: (theme) => theme.palette.background.paper,
        minWidth: '1024px'
      }}
    >

      {/* body */}
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {/* toggler annual/monthly */}
        {
          <PlanDuration
            pricingOption={pricingOption}
            handleClick={handleClick}
          />
        }
        {/* plans cards */}
        <Box
          sx={{
            flexWrap: "nowrap",
            alignItems: 'center',
            overflow: "auto",
            gap: '1rem',
            display: 'flex',
            width: '100%',
            padding: '1rem',
          }}
        >
          {mokPlans.map((item, i) => (
            <CardPlan
              data={item}
              pricingOption={pricingOption}
              handleOpenModalUpgrade={handleOpenModalUpgrade}
              key={i}
            />
          ))}
        </Box>
        <Typography
          align={'center'}
          variant={'caption'}
          color="text.secondary"
          component={'p'}
        >
          *{t('pricing.trialAdvice')}
        </Typography>
      </Box>
      {
        openModalUpgrade && (
          <ModalUpgrade
            openModalUpgrade={openModalUpgrade}
            handleCloseModalUpgrade={handleCloseModalUpgrade}
            acceptNewPlan={acceptNewPlan}
            actualPlan={actualPlan}
            newPlan={newPlan}
          />
        )
      }
    </Box>
  )
}

export default PlanType