// Translations
import { useTranslation } from "react-i18next";
// Components/ui
import { Box, Button, Card, Grid, Typography } from "@mui/material"
// Icons
import { Add } from "@mui/icons-material";
// Types
import { ISubscriptionProduct } from "@trii/types/dist/Accounts";
// Styles
import productCardStyles from "./styles";

interface Props {
  title: string;
  product: ISubscriptionProduct;
  renderHeader?: boolean;
  handleOpen: () => void;
  children: React.ReactNode;
}

const ProductCard = ({
  title,
  product,
  renderHeader = true,
  handleOpen,
  children,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Card
      sx={{
        ...productCardStyles.container,
        minHeight: renderHeader ? '11rem' : '8.5rem',
      }}
    >
      <Box
        sx={productCardStyles.header}
      >
        <Typography
          variant="h5"
          noWrap
        >
          {t(title)}
        </Typography>
        <Button
          variant="outlined"
          size="small"
          startIcon={<Add />}
          onClick={handleOpen}
        >
          {t('plans.otherProducts.add')}
        </Button>
      </Box>
      <Grid
        container
        spacing={1}
        columns={2}
      >
        {
          renderHeader && (
            <>
              {/* Information */}
              <Grid
                item
                width='10rem'
                height='2.5rem'
              >
                <Typography
                  variant="body1"
                  sx={{
                    ...productCardStyles.information,
                    bgcolor: (theme) => theme.palette.grey[300],
                  }}
                >
                  {t('plans.otherProducts.paymentPerUse')}
                </Typography>
              </Grid>
              <Grid
                item
                width='calc(100% - 10rem)'
                height='2.5rem'
              >
                <Box
                  sx={{
                    ...productCardStyles.information,
                    bgcolor: (theme) => `${theme.palette.primary.light}30`,
                    justifyContent: 'space-between'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '.5rem',
                    }}
                  >
                    <Typography
                      fontSize={14}
                      variant="body1"
                    >
                      {t('plans.otherProducts.emailCost')}
                    </Typography>
                    <Typography
                      variant="body1"
                      fontWeight='bold'
                      fontSize={14}
                    >
                      US${product.price}
                    </Typography>
                  </Box>
                  <Typography
                    variant="body1"
                    fontSize={12}
                  >
                    {t('plans.otherProducts.requiresPlan')}
                  </Typography>
                </Box>
              </Grid>
            </>
          )
        }
        {/* Body */}
        <Grid
          item
          width='10rem'
          height='4rem'
        >
          <Typography
            variant="body1"
            sx={{
              ...productCardStyles.information,
              bgcolor: (theme) => theme.palette.grey[300],
              color: (theme) => theme.palette.grey[800],
              fontWeight: 'bold',
              fontSize: 30,
            }}
          >
            {product.name}
          </Typography>
        </Grid>
        <Grid
          item
          width='calc(100% - 10rem)'
          height='4rem'
        >
          <Box
            sx={{
              ...productCardStyles.information,
              bgcolor: (theme) => `${theme.palette.primary.light}30`,
              justifyContent: 'space-between'
            }}
          >
            {children}
          </Box>
        </Grid>
      </Grid>
    </Card>
  )
}

export default ProductCard