// Components/ui
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react';

initMercadoPago('YOUR_PUBLIC_KEY');

const MercadoPagoTabContent = () => {
  return (
    <div>
      <Wallet
        initialization={{
          preferenceId: '',
        }}
      />
    </div>
  );
};

export default MercadoPagoTabContent;
