import { useLocation, Navigate, Outlet } from 'react-router-dom';
// Redux
import { useDispatch } from 'react-redux';
import { setSignInRedirect } from 'ReduxToolkit/features/sessionSlice/sessionSlice';

interface Props {
  isAuth: boolean;
  redirectTo: string;
  isPublic: boolean;
}

const RequireAuth = ({ isAuth, redirectTo, isPublic }: Props) => {
  let redirectUrl = redirectTo;
  const location = useLocation();
  const dispatch = useDispatch();
  const decodedURI = decodeURIComponent(window.location.search);
  const params = new URLSearchParams(decodedURI);
  const redirectParam = params.get('redirect');

  if (!isAuth && !isPublic) {
    const encodedUrlPath = encodeURIComponent(window.location.pathname);
    const redirectUrl = `${window.location.protocol}//${window.location.host}/signin?redirect=${encodedUrlPath}`;
    dispatch(setSignInRedirect(redirectUrl));
  }

  if (redirectParam) redirectUrl = redirectParam;

  return isAuth ? (
    <Outlet />
  ) : (
    <Navigate to={redirectUrl} state={{ from: location }} replace />
  );
};

export default RequireAuth;
