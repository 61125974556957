// Components
import SubscriptionContainer from './components/SubscriptionContainer/SubscriptionContainer';
import { SubscriptionProvider } from './context/SubscriptionProvider';

export default function Subscription() {

  return (
    <SubscriptionProvider>
      <SubscriptionContainer />
    </SubscriptionProvider>
  );
}
