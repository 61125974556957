import { useEffect, useState } from "react";
// Redux
import { useSelector } from "react-redux";
// Translations
import { useTranslation } from "react-i18next";
// Components/ui
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from "@mui/material";
// Icons
import { CheckCircle, CheckCircleOutline } from "@mui/icons-material";
// Types
import { Plan } from "features/Plans/context/types/Plan";
// Slice
import {
  subscriptionSelectorsById,
  subscriptionStatusSelectors
} from "ReduxToolkit/features/subscriptionSlice/subscriptionSlice";

interface CardPlanProps {
  data: Plan;
  pricingOption: string;
  handleOpenModalUpgrade: () => void;
}

const CardPlan = ({
  data,
  pricingOption,
  handleOpenModalUpgrade,
}: CardPlanProps) => {
  const { t } = useTranslation();
  const subscription = useSelector(subscriptionSelectorsById);
  const subscriptionStatus = useSelector(subscriptionStatusSelectors);
  const isLoading = subscriptionStatus === 'loading';
  const [isSubscribed, setIsSubscribed] = useState(false);

  useEffect(() => {
    if (data.planType === subscription?.plan?.usersType) {
      setIsSubscribed(true);
    } else {
      setIsSubscribed(false);
    }
    return () => {
      setIsSubscribed(false);
    }
  }, [subscription])

  return (
    <Card
      sx={{
        height: 1,
        display: "flex",
        flexDirection: "column",
        variant: "outlined",
        backgroundColor: (theme) =>
          isSubscribed && !isLoading
            ? `${theme.palette.primary.light}30`
            : theme.palette.background.paper,
        boxShadow: "0 0 10px 0 rgb(33 43 54 / 20%)",
        marginTop: isSubscribed && !isLoading ? 0 : "-55px",
        transform: isSubscribed && !isLoading ? "scale(1)" : "scale(.9)",

        borderRadius: "1rem",
        transition: "all .3s ease-in-out",
      }}
    >
      <CardContent
        sx={{
          padding: 2.5,
          minHeight: "500px",
        }}
      >
        <Box marginBottom={2}>
          <Typography variant={"h4"} fontWeight={600} gutterBottom>
            {t(data.title)}
          </Typography>
          <Typography color={"text.secondary"}>{t(data.subtitle)}</Typography>
        </Box>
        <Box display={"flex"} alignItems={"baseline"} marginBottom={2}>
          <Typography variant={"h3"} fontWeight={700}>
            {pricingOption === "annual"
              ? data.price.annual
              : data.price.monthly}
          </Typography>
          <Typography
            variant={"subtitle1"}
            color={"text.secondary"}
            fontWeight={700}
          >
            {pricingOption === "annual" ? "/y" : "/mo"}
          </Typography>
        </Box>
        <Grid container spacing={1}>
          {data.features.map((feature: string, i: number) => (
            <Grid xs={12} key={i}>
              <ListItem
                disableGutters
                sx={{
                  padding: 0,
                  width: "auto",
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: "auto",
                    marginRight: 2,
                  }}
                >
                  <CheckCircle
                    sx={{
                      color:
                        isSubscribed && !isLoading
                          ? "primary.main"
                          : "text.secondary",
                      fontSize: "1.2rem",
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary={t(feature)} />
              </ListItem>
            </Grid>
          ))}
        </Grid>
      </CardContent>
      <Box flexGrow={1} />
      <CardActions
        sx={{ justifyContent: "flex-end", padding: 1, paddingBottom: 2 }}
      >
        {isSubscribed && !isLoading ? (
          <>
            <Button
              sx={{ whiteSpace: "nowrap", textAlign: "center" }}
              disabled
              size={"small"}
              variant={"contained"}
              startIcon={<CheckCircleOutline />}
            >
              {t("subscription.currentPlan")}
            </Button>
          </>
        ) : (
          <Button
            size={"small"}
            variant={"contained"}
            onClick={handleOpenModalUpgrade}
          >
            {data.planType === "PRO"
              ? t("subscription.upgrade")
              : t("subscription.change")}
          </Button>
        )}
      </CardActions>
    </Card>
  );
}

export default CardPlan