import { Plan } from "../context/types/Plan";

export const mokPlans: Plan[] = [
  {
    planType: 'BASIC',
    title: 'subscription.basic',
    subtitle: 'pricing.accessAllFeatures',
    price: { monthly: '$22', annual: '$190' },
    features: [
      'pricing.accountLineNumber',
      'pricing.messageOnlyText',
      'pricing.multimediaMessage',
      'pricing.webhookNotification',
      'pricing.5daysTrial',
      'pricing.requiresCreditCard',
    ],
    isSubscribed: true
  },
  {
    planType: 'PRO',
    title: 'subscription.pro',
    subtitle: 'pricing.accessAllFeatures',
    price: { monthly: '$22', annual: '$190' },
    features: [
      'pricing.accountLineNumber',
      'pricing.messageOnlyText',
      'pricing.multimediaMessage',
      'pricing.webhookNotification',
      'pricing.5daysTrial',
      'pricing.requiresCreditCard',
    ],
    isSubscribed: false,
  },
]