import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// Hooks
import useField from 'hooks/useField';
import { useAppDispatch } from 'hooks/useAppDispatch';
// Redux
import { useSelector } from 'react-redux';
import {
  selectUser,
  updateUserName,
  getUserStatus,
} from 'ReduxToolkit/features/userSlice/userSlice';
// Components/ui
import { nameInputControl } from 'helpers/authControl';
import { Input, FormNotificationError } from 'components';
import Spinner from 'react-bootstrap/Spinner';
import { TextField, Grid, Box, Button } from '@mui/material';

const PersonalInformationForm = () => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const userStatus = useSelector(getUserStatus);
  const dispatch = useAppDispatch();
  const nameInput = useField('name', user.name);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const name = nameInput.atributes.value;
    const userNameObj = {
      name,
    };

    if (inputFormControl(name)) await dispatch(updateUserName(userNameObj));

    setIsLoading(false);
  };

  const inputFormControl = (name) => {
    if (nameInputControl(name)) {
      nameInput.actions.onSuccess();
      return true;
    }

    nameInput.actions.onError(
      <FormNotificationError errorMsg={t('profile.error.invalidName')} />
    );
    return false;
  };

  useEffect(() => {
    nameInput.actions.onSetNewValue(user.name);
  }, [user]); //eslint-disable-line

  return (
    <form onSubmit={handleSubmit} className="form-group">
      <Grid item container xs={12}>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', sm: 'row' }}
          alignItems={{ xs: 'stretched', sm: 'center' }}
          justifyContent={'flex-'}
          width={1}
          margin={'0 auto'}
        >
          <Box
            display="flex"
            flexDirection={{
              xs: 'column',
              sm: 'column',
              md: 'column',
            }}
            alignItems={{
              xs: 'stretched',
              sm: 'center',
              md: 'start',
            }}
            gap={0.3}
            justifyContent={'flex-start'}
            className={'marginLeftImgProfile'}
            width={1}
            margin={'0 auto'}
          >
            {userStatus === 'succeeded' && (
              <TextField
                label="Email"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
                disabled
                value={user.email}
                size="small"
                fullWidth
                variant="outlined"
              />
            )}
            <Box mb={2} />
            <Grid container spacing={4}>
              {userStatus === 'succeeded' && (
                <Input {...nameInput} label={t('profile.enterName')} />
              )}
            </Grid>
            <Grid mt={2} item container xs={12}>
              <Box
                display="flex"
                flexDirection={{ xs: 'column', sm: 'row' }}
                alignItems={{ xs: 'stretched', sm: 'center' }}
                justifyContent={'flex-end'}
                width={1}
                margin={'0 auto'}
              >
                <Button
                  size="small"
                  type="submit"
                  variant={'contained'}
                  disabled={isLoading}
                  startIcon={
                    isLoading ? (
                      <Spinner
                        style={{
                          height: '1rem',
                          width: '1rem',
                          fontSize: '12px',
                        }}
                        animation="border"
                      />
                    ) : null
                  }
                >
                  {isLoading ? t('global.saving') : t('global.save')}
                </Button>
              </Box>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </form>
  );
};

export default PersonalInformationForm;
