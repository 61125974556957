// Types
import { InvoiceStatus } from "@trii/types/dist/Accounts"

export const getStatusColor = (status: InvoiceStatus ) => {
    switch (status) {
        case InvoiceStatus.DRAFT:
        return 'lightgray';
        case InvoiceStatus.SENT:
        return 'lightgray';
        case InvoiceStatus.PAID:
        return '#2dd4bf';
        case InvoiceStatus.OVERDUE:
        return '#ef4444';
        case InvoiceStatus.VOID:
        return '#2dd4bf';
        case InvoiceStatus.UNCOLLECTIBLE:
        return 'black';
        }
    }