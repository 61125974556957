import { combineReducers } from '@reduxjs/toolkit';
// Reducers
import sessionReducer from './features/sessionSlice/sessionSlice';
import poolReducer from './features/poolSlice/poolSlice';
import userReducer from './features/userSlice/userSlice';
// Slice
import spaceSlice from './features/spaceSlice/spaceSlice';
import spacesSlice from './features/spacesSlice/spacesSlice';
import subscriptionSlice from './features/subscriptionSlice/subscriptionSlice';
import invoiceSlice from './features/invoiceSlice/invoiceSlice';
import addressSlice from './features/addressSlice/addressSlice';
import workspaceSlice from './features/workspace/workspaceSlice';
import subdomainSlice from './features/subdomainSlice/subdomainSlice';
import auditLogSlice from './features/auditLogSlice/auditLogSlice';

const rootReducer = combineReducers({
  Space: spaceSlice,
  Spaces: spacesSlice,
  Session: sessionReducer,
  Pool: poolReducer,
  User: userReducer,
  Subscription: subscriptionSlice,
  Invoice: invoiceSlice,
  Address: addressSlice,
  Workspace: workspaceSlice,
  Subdomain: subdomainSlice,
  AuditLog: auditLogSlice,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
