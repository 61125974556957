// Types
import { InvoiceStatus } from '@trii/types/dist/Accounts';
// Components/ui 
import CheckIcon from '@mui/icons-material/Check';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import DrawIcon from '@mui/icons-material/Draw';
import BlockIcon from '@mui/icons-material/Block';

import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { style } from './style'

interface Props {
    status: InvoiceStatus
 }

const StatusIcon = ({ status }:Props) => {
    if (status === InvoiceStatus.PAID) return <CheckIcon sx={style} fontSize='small' />
    else if (status === InvoiceStatus.OVERDUE) return <WarningAmberIcon sx={style} fontSize='small' />
    else if (status === InvoiceStatus.DRAFT) return <DrawIcon sx={style} fontSize='small' />
    else if (status === InvoiceStatus.VOID) return <BlockIcon sx={style} fontSize='small' />
    else if (status === InvoiceStatus.UNCOLLECTIBLE) return <RemoveCircleIcon sx={style} fontSize='small' />
    else if (status === InvoiceStatus.SENT) return <WarningAmberIcon sx={style} fontSize='small' />
}

export default StatusIcon