import { useContext, useEffect } from 'react';
// Redux
import { useSelector } from 'react-redux';
// Slice
import {
  subscriptionSelectors,
  subscriptionStatusSelectors,
} from 'ReduxToolkit/features/subscriptionSlice/subscriptionSlice';
// Context
import { subscriptionContext } from 'features/Subscription/context/SubscriptionProvider';
// Components/ui
import { Box } from '@mui/material';
// Components
import { Subscription } from './components';

export default function SubscriptionList() {
  const newSubscriptionList = useSelector(subscriptionSelectors);
  const subscriptionStatus = useSelector(subscriptionStatusSelectors);
  const isLoading = subscriptionStatus === 'loading';
  const {
    subscriptions,
    setSubscriptions,
  } = useContext(subscriptionContext);

  useEffect(() => {
    if (newSubscriptionList && newSubscriptionList.length > 0) {
      setSubscriptions(newSubscriptionList);
    }
  }, [newSubscriptionList])

  return (
    <Box
      maxHeight={{ md: '78vh', xl: '80vh' }}
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        gap: '1rem',
        overflowY: 'auto',
      }}
      paddingLeft={{ md: 5, lg: 7 }}
      paddingRight={{ md: 5, lg: 7 }}
      py={2}
    >
      {subscriptions.map((subscription) => (
        <Subscription subscriptionData={subscription} key={subscription.id} />
      ))}
    </Box>
  );
}
