/* eslint-disable import/no-anonymous-default-export */
import getRequestConfig from 'ReduxToolkit/functions/getRequestConfig';
import axios from 'axios';

//GET
const getAddress = async (
  jwt: string,
  URL: string,
  id: string,
) => {
  const requestConfig = getRequestConfig.contentTypeJson(jwt);

  const response = await axios.get(`${URL}/Spaces/${id}`, requestConfig);
  return response.data;
};

const getAddressList = async (
  jwt: string,
  URL: string,
) => {
  const requestConfig = getRequestConfig.contentTypeJson(jwt);


  const response = await axios.get(`${URL}/Spaces`, requestConfig);
  return response.data;
};

//PUT
const updateAddress = async (
  jwt: string,
  URL: string,
  data: any
) => {
  const requestConfig = getRequestConfig.contentTypeJson(jwt);
  const dataJSON = JSON.stringify(data);

  const response = await axios.put(`${URL}/Spaces`, dataJSON, requestConfig);
  return response.data;
};



export default {
  getAddress,
  getAddressList,
  updateAddress,
};
