/* eslint-disable import/no-anonymous-default-export */
import { IInvoice, IInvoiceBalance } from '@trii/types/dist/Accounts';
import getRequestConfig from 'ReduxToolkit/functions/getRequestConfig';
import axios from 'axios';

//GET
const getInvoice = async (
  jwt: string,
  URL: string,
  id: string
  ) => {
    const requestConfig = getRequestConfig.basic(jwt);

    const response = await axios.get(
      `${URL}/Invoices/${id}`,
      requestConfig
    );

  return response.data;
};

const getInvoices = async (
  jwt: string,
  URL: string,
): Promise<IInvoice[]> => {
  const requestConfig = getRequestConfig.basic(jwt);

  const response = await axios.get<IInvoice[]>(
    `${URL}/Invoices`,
    requestConfig
  );

  return response.data;
};

const getBalance = async (
  jwt: string,
  URL: string,
): Promise<IInvoiceBalance> => {
  const requestConfig = getRequestConfig.basic(jwt);

  const response = await axios.get<IInvoiceBalance>(
    `${URL}/Invoices/balance`,
    requestConfig
  );

  return response.data;
};

export default {
  getInvoice,
  getInvoices,
  getBalance
};
