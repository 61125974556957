import { createContext, useState } from "react";
// Hooks
import { useAppDispatch } from "hooks/useAppDispatch";
// Slice
import { 
  fetchSubscriptionList,
 } from "ReduxToolkit/features/subscriptionSlice/subscriptionSlice";
// Types
import { ISubscription } from "@trii/types/dist/Accounts";
import { SubscriptionContext } from "./types/SubscriptionContext";

export const subscriptionContext = createContext<SubscriptionContext>({
  subscriptions: [],
  setSubscriptions: () => { },
  getSubscriptions: () => { },
});

interface SubscriptionProps {
  children: React.ReactNode;
}

export const SubscriptionProvider = ({
  children
}: SubscriptionProps) => {
  const [subscriptions, setSubscriptions] = useState<ISubscription[]>([]);
  const dispatch = useAppDispatch();

  const getSubscriptions = async () => {
    try {
        await dispatch(fetchSubscriptionList());
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <subscriptionContext.Provider
      value={{
        subscriptions,
        setSubscriptions,
        getSubscriptions,
      }}>
      {children}
    </subscriptionContext.Provider>
  );
}