import { useState, useContext } from "react"
// Translations
import { useTranslation } from "react-i18next";
// Components/ui
import { Box, Button, Typography } from "@mui/material"
// Components
import { ModalChannel, ModalList, ProductCard } from "./components"
// Context
import { plansContext } from "features/Plans/context/PlansProvider";

const OtherProducts = () => {
  const {
    channelQuantity,
    setChannelQuantity,
    usersQuantity,
    setUsersQuantity,
  } = useContext(plansContext);
  const { t } = useTranslation();
  const [openUsers, setOpenUsers] = useState(false);
  const [openEmail, setOpenEmail] = useState(false);
  const [openSms, setOpenSms] = useState(false);
  const [openWhatsApp, setOpenWhatsApp] = useState(false);
  const [modalChannelTitle, setModalChannelTitle] = useState('');

  const handleOpen = (setState: React.Dispatch<React.SetStateAction<boolean>>, title?: string) => {
    setState(true);
    setModalChannelTitle(title);
  }

  const handleClose = (setState: React.Dispatch<React.SetStateAction<boolean>>) => {
    setState(false);
    setModalChannelTitle('');
  }

  return (
    <Box
      width={'100%'}
      height={'100%'}
      p={2}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        overflowY: 'scroll',
      }}
    >
      {/* USERS */}
      <ProductCard
        title={'plans.otherProducts.users'}
        product={{
          name: '5',
          quantity: 2,
          price: 0.0007,
          expiresAt: new Date(),
        }}
        renderHeader={false}
        handleOpen={() => handleOpen(
          setOpenUsers,
          'plans.otherProducts.users'
        )}
      >
        <Box
          display={'flex'}
          gap={'1rem'}
          alignItems='center'
        >
          <Typography
            variant="body1"
            fontSize={15}
            fontWeight='bold'
            color={(theme) => theme.palette.grey[800]}
          >
            {t('plans.otherProducts.inUse', { quantity: 3 })}
          </Typography>
        </Box>
      </ProductCard>
      {/* EMAILS */}
      <ProductCard
        title={'plans.otherProducts.emailsPack'}
        product={{
          name: '50k',
          quantity: 2,
          price: 0.0007,
          expiresAt: new Date(),
        }}
        handleOpen={() => handleOpen(setOpenEmail)}
      >
        <Typography
          variant="body1"
          fontWeight='bold'
          fontSize={30}
          color={(theme) => theme.palette.grey[600]}
        >
          US$26.50
        </Typography>
        <Box
          sx={{
            display: 'flex',
            gap: '.5rem',
          }}
        >
          <Typography
            fontSize={14}
            variant="body1"
          >
            {t('plans.otherProducts.emailExededCost')}
          </Typography>
          <Typography
            variant="body1"
            fontWeight='bold'
            fontSize={14}
            color={(theme) => theme.palette.grey[600]}
          >
            US$0.00053
          </Typography>
        </Box>
      </ProductCard>
      {/* SMS */}
      <ProductCard
        title={'plans.otherProducts.sms'}
        product={{
          name: '1k',
          quantity: 2,
          price: 0.06,
          expiresAt: new Date(),
        }}
        handleOpen={() => handleOpen(setOpenSms)}
      >
        <Typography
          variant="body1"
          fontWeight='bold'
          fontSize={30}
          color={(theme) => theme.palette.grey[600]}
        >
          US$26.50
        </Typography>
        <Box
          sx={{
            display: 'flex',
            gap: '.5rem',
          }}
        >
          <Typography
            fontSize={14}
            variant="body1"
          >
            {t('plans.otherProducts.emailExededCost')}
          </Typography>
          <Typography
            variant="body1"
            fontWeight='bold'
            fontSize={14}
            color={(theme) => theme.palette.grey[600]}
          >
            US$0.00053
          </Typography>
        </Box>
      </ProductCard>
      {/* WHATSAPP */}
      <ProductCard
        title={'plans.otherProducts.whatsApp'}
        product={{
          name: '5',
          quantity: 2,
          price: 0.0007,
          expiresAt: new Date(),
        }}
        renderHeader={false}
        handleOpen={() => handleOpen(
          setOpenWhatsApp,
          'plans.otherProducts.channels'
        )}
      >
        <Typography
          variant="body1"
          fontWeight='bold'
          fontSize={30}
          color={(theme) => theme.palette.grey[600]}
        >
          US$26.50
        </Typography>
        <Box
          display={'flex'}
          gap={'1rem'}
          alignItems='center'
        >
          <Typography
            variant="body1"
            fontSize={15}
            fontWeight='bold'
            color={(theme) => theme.palette.grey[800]}
          >
            {t('plans.otherProducts.inUse', { quantity: 3 })}
          </Typography>
          <Button
            variant="outlined"
          >
            {t('plans.otherProducts.remove', { quantity: 2 })}
          </Button>
        </Box>
      </ProductCard>
      {
        (openEmail || openSms) && (
          <ModalList
            open={openEmail || openSms}
            onClose={() => handleClose(
              openEmail ? setOpenEmail : setOpenSms
            )}
          />
        )
      }
      {
        (openWhatsApp || openUsers) && (
          <ModalChannel
            open={openWhatsApp || openUsers}
            onClose={() => handleClose(
              openWhatsApp ? setOpenWhatsApp : setOpenUsers
            )}
            channelTitle={modalChannelTitle}
            setState={openWhatsApp ? setChannelQuantity : setUsersQuantity}
            value={openWhatsApp ? channelQuantity : usersQuantity}
          />
        )
      }
    </Box>
  )
}

export default OtherProducts