import { useEffect, useState } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
  selectSignInRedirect,
  setSignInRedirect,
} from 'ReduxToolkit/features/sessionSlice/sessionSlice';
// Custom hooks
import useField from 'hooks/useField';
// Components/ui
import { SignInFlow } from './components';
import { Main } from 'components/layouts';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Fade from '@mui/material/Fade';

export default function SignIn() {
  const dispatch = useDispatch();
  const signInRedirect = useSelector(selectSignInRedirect);
  const emailField = useField('text');
  const [fadeIn, setFadeIn] = useState(false);
  const { t } = useTranslation();
  const [stage, setStage] = useState('signIn');

  useEffect(() => {
    setFadeIn(true);
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    if (signInRedirect) history.pushState(null, null, signInRedirect);

    return () => {
      if (signInRedirect) dispatch(setSignInRedirect(null));
    };
  }, [dispatch, signInRedirect]);

  return (
    <Main>
      <Box bgcolor={'alternate.main'}>
        <Fade in={fadeIn}>
          <Container
            style={{
              maxWidth: 800,
              height: '85vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Box marginBottom={4} display={'flex'}>
              <Box
                component={'img'}
                src={'/img/Trii.svg'}
                sx={{ marginRight: '15px' }}
                height={100}
              ></Box>
              <Box>
                <Typography
                  sx={{
                    textTransform: 'uppercase',
                  }}
                  gutterBottom
                  color={'text.secondary'}
                  fontWeight={700}
                >
                  {t('global.login')}
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 700,
                  }}
                >
                  {t('login.welcomeBack')}
                </Typography>
                <Typography color="text.secondary">
                  {stage === 'signIn' && t('login.logInToManageAccount')}
                  {stage === 'emailVerification' && t('verify.verifyYourEmail')}
                </Typography>
              </Box>
            </Box>
            <Card sx={{ p: { xs: 4, md: 6 } }}>
              <SignInFlow
                setStage={setStage}
                stage={stage}
                emailField={emailField}
              />
            </Card>
          </Container>
        </Fade>
      </Box>
    </Main>
  );
}
