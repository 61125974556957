import { Outlet } from 'react-router-dom';
// Components/ui
import { useContext } from 'react';
import NavMenu from './NavMenu';
import EnlacesLogin from './EnlacesLogin';
import './MainLayout.css';
import { DataContext } from '../context/contextApi';
import { Box, Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const MainLayout = () => {
  // function MainLayout(props) {
  const { openDialogEmpresa, setOpenDialogEmpresa, isMobile, showPageSideBar } =
    useContext(DataContext);

  const closeDialog = async () => {
    setOpenDialogEmpresa(false);
  };

  return (
    <>
      <Box className={isMobile ? "page-wrapper-mobile page-wrapper" : "page-wrapper" }>
        <Box className="page-inner">
         
            <Box
              className={
                !isMobile
                  ? 'page-sidebar sidebar-sticky'
                  : 'page-sidebar sidebar-sticky page-sidebar-mobile'
              }
            >
              <NavMenu />
            </Box>
        
          <Box className="fullWidth">
            <Box
              className="page-header header-sticky"
              style={{ padding: '0px !important' }}
            >
              <EnlacesLogin authenticated={true} />
            </Box>
            <Box id="js-page-content" className={isMobile ? "fullvw page-content" : "page-content"} >
              {/* {children} */}
              <Outlet />
              <Dialog
                open={openDialogEmpresa}
                onClose={() => setOpenDialogEmpresa(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">title</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    subtitle
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setOpenDialogEmpresa(false)}>
                    {/* {t("global.cancel")} */}
                    cancel
                  </Button>
                  <Button color="error" onClick={closeDialog}>
                    {/* {t("global.delete")} */}
                    delete
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MainLayout;
