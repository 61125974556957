// Redux
import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { initRequestData } from 'ReduxToolkit/functions/initRequestData';
import InitRequestDataReturn from 'ReduxToolkit/types/InitRequestDataReturn';
import { RootState } from 'ReduxToolkit/rootReducer';
// Service
import addressService from './addressService';
// Types
import { AddressState } from './types/AddressState';

const initialState: AddressState = {
  status: {
    fetch: 'idle',
  },
  address: {
    firstName: 'Lautaro',
    lastName: 'Croatto',
    address: 'Edison 170',
    cuit: '27394765768',
  },
};

export const getAddress = createAsyncThunk<any, void>(
  'Address/getAddress',
  async (_, { dispatch }) => {
    const { jwtToken, URL_ACCOUNT } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const addresses = await addressService.getAddressList(
      jwtToken,
      URL_ACCOUNT
    );

    return addresses;
  }
);

export const addressSlice = createSlice({
  name: 'addresses',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAddress.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(getAddress.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        const addresses = action.payload;
        state.address = addresses;
        console.log('getAddress succeeded: ', action.payload)
      })
      .addCase(getAddress.rejected, (state, action) => {
        state.status.fetch = 'rejected';
        console.log('getAddress rejected: ', action.error)
      })
  },
});

// selectors
const addressState = (state: RootState) => state.Address;
export const selectAddress = createSelector(
  addressState,
  (address) => address.address
)
export const selectAddressStatus = createSelector(
  addressState,
  (address) => address.status.fetch
)

export default addressSlice.reducer;
