/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios';
import getRequestConfig from '../../functions/getRequestConfig';

const fetchSpaces = async (jwt: string) => {
  const config = getRequestConfig.basic(jwt);

  const response = await axios.get(
    'https://account.trii.app/api/v1/MySpaces',
    config
  );

  return response.data;
};

const joinSpace = async (jwt: string, id: string) => {
  const config = getRequestConfig.basic(jwt);

  const response = await axios.put(
    `https://account.trii.app/api/v1/Spaces/joinspace`,
    { id, },
    config
  );

  return response.data;
}

export default {
  fetchSpaces,
  joinSpace,
};
