import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  CookieStorage,
  CognitoUser,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { RootState } from '../../rootReducer';
// Helper
import sessionHelper from './sessionHelper';

const initialState = {
  session: null,
  status: {
    setSession: 'idle',
    setSessionRefresh: 'idle',
  },
  signInRedirect: null,
};

export const setSession = createAsyncThunk<
  CognitoUserSession,
  void,
  { state: RootState }
>('session/setSession', async (_, { getState }) => {
  return new Promise((resolve, reject) => {
    const { pool } = getState().Pool;
    const user = pool.getCurrentUser();

    if (user) {
      user.getSession((err, session: CognitoUserSession) => {
        if (err) {
          reject(err);
        } else {
          resolve(sessionHelper.checkSessionValidity(user, session));
        }
      });
    } else {
      reject(false);
    }
  });
});

export const logout = createAsyncThunk<void, void, { state: RootState }>(
  'session/logout',
  async (_, { getState }) => {
    const { pool } = getState().Pool;
    const user = pool.getCurrentUser();

    if (user) {
      console.log('logout: ');
      await user.signOut();
      window.location.href = ` ${window.location.protocol}//${window.location.host}/signin`;
    }
  }
);

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSignInRedirect: (state, action: PayloadAction<string | null>) => {
      const url = action.payload;
      state.signInRedirect = url;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(setSession.pending, (state) => {
        if (!state.session) state.status.setSession = 'loading';
        else state.status.setSessionRefresh = 'loading';
      })
      .addCase(setSession.fulfilled, (state, action) => {
        const session = action.payload;

        if (!state.session) {
          state.status.setSession = 'succeeded';
          console.log('Success fetching session: ', session);
        } else {
          state.status.setSessionRefresh = 'idle';
          console.log('Success refreshing session: ', session);
        }
        state.session = session;
      })
      .addCase(setSession.rejected, (state, action) => {
        console.log('error fetching session: ', action.error);
        state.status.setSession = 'failed';
      });
  },
});

// Actions
export const { setSignInRedirect } = sessionSlice.actions;

// Selectors
export const selectSession = (state: RootState) => state.Session.session;
export const getSessionStatus = (state: RootState) =>
  state.Session.status.setSession;
export const selectSignInRedirect = (state: RootState) =>
  state.Session.signInRedirect;

export default sessionSlice.reducer;
