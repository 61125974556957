// Redux
import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { RootState } from 'ReduxToolkit/rootReducer';
import { initRequestData } from 'ReduxToolkit/functions/initRequestData';
import InitRequestDataReturn from 'ReduxToolkit/types/InitRequestDataReturn';
// Services
import SubscriptionService from './subscriptionService';
// Types
import { SubscriptionState } from './types/SubscriptionState';

const initialState: SubscriptionState = {
  status: {
    fetch: 'idle',
  },
  subscriptions: [],
  subscription: null,
};

export const fetchSubscriptionList = createAsyncThunk(
  'Subscription/fetchSubscriptionList',
  async (_, { dispatch }) => {

    const { jwtToken, URL_ACCOUNT } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const Subscriptiones = await SubscriptionService.fetchSubscriptionList(
      jwtToken,
      URL_ACCOUNT
    );

    return Subscriptiones;
  }
);

export const fetchSubscription = createAsyncThunk(
  'Subscription/fetchSubscription',
  async (SubscriptionId: string, { dispatch }) => {
    const { jwtToken, URL_ACCOUNT } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const Subscription = await SubscriptionService.fetchSubscription(
      jwtToken,
      URL_ACCOUNT,
      SubscriptionId
    );

    return Subscription;
  }
);

export const subscriptionSlice = createSlice({
  name: 'Subscriptiones',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchSubscriptionList.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchSubscriptionList.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        state.subscriptions = action.payload;
        console.log('fetchSubscriptionList succeeded: ', action.payload)
      })
      .addCase(fetchSubscriptionList.rejected, (state, action) => {
        state.status.fetch = 'rejected';
        console.log('fetchSubscriptionList rejected: ', action.error)
      })
      .addCase(fetchSubscription.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchSubscription.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        state.subscription = action.payload;
        console.log('fetchSubscription succeeded: ', action.payload)
      })
      .addCase(fetchSubscription.rejected, (state, action) => {
        state.status.fetch = 'rejected';
        console.log('fetchSubscription rejected: ', action.error)
      });
  },
});

// selectors
const subscriptionState = (state: RootState) => state.Subscription;
export const subscriptionSelectors = createSelector(
  subscriptionState,
  (state) => state.subscriptions
)
export const subscriptionStatusSelectors = createSelector(
  subscriptionState,
  (state) => state.status.fetch
)
export const subscriptionSelectorsById = createSelector(
  subscriptionState,
  (state) => state.subscription
)

export default subscriptionSlice.reducer;