export const mockList = [
  {
    id: "1",
    quantity: "50k",
    costPerMonth: "26.50",
    costExeded: "0.00053"
  },
  {
    id: "2",
    quantity: "100k",
    costPerMonth: "53.00",
    costExeded: "0.00053"
  },
  {
    id: "3",
    quantity: "250k",
    costPerMonth: "132.50",
    costExeded: "0.00053"
  },
  {
    id: "4",
    quantity: "500k",
    costPerMonth: "265.00",
    costExeded: "0.00053"
  },
  {
    id: "5",
    quantity: "1M",
    costPerMonth: "530.00",
    costExeded: "0.00053"
  },
  {
    id: "6",
    quantity: "2.5M",
    costPerMonth: "1325.00",
    costExeded: "0.00053"
  },
  {
    id: "7",
    quantity: "5M",
    costPerMonth: "2650.00",
    costExeded: "0.00053"
  },
]