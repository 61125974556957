import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// Utils
import usersService from './userSliceService';
import { initRequestData } from 'ReduxToolkit/functions/initRequestData';
// Types
import InitRequestDataReturn from 'ReduxToolkit/types/InitRequestDataReturn';
import { IUserState } from './types/UserSliceState';
import { RootState } from 'ReduxToolkit/store';

const initialState: IUserState = {
  user: null,
  status: {
    user: 'idle',
    nameChange: 'idle',
    cancelDeletionStatus: 'idle',
    deleteUser: 'idle',
  },
  error: {
    user: null,
    nameChange: null,
    cancelDeletionStatus: null,
    deleteUser: null,
  },
};

export const setUser = createAsyncThunk('User/setUser', async (_, { dispatch }) => {
  const { jwtToken, URL_ACCOUNT } = (await dispatch(initRequestData()))
    .payload as InitRequestDataReturn;

  const user = await usersService.getUser(jwtToken, URL_ACCOUNT);

  return user;
});

export const updateUserName = createAsyncThunk(
  'User/updateUserName',
  async (userNameJson: any, { dispatch }) => {
    const { jwtToken, URL_ACCOUNT } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const user = await usersService.updateUser(jwtToken, URL_ACCOUNT, userNameJson);

    return user;
  }
);

export const cancelDeletionStatus = createAsyncThunk(
  'User/cancelDeletionStatus',
  async (statusJson: { status: number }, { dispatch }) => {
    const { jwtToken, URL_ACCOUNT } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const user = await usersService.updateUser(jwtToken, URL_ACCOUNT, statusJson);

    return user;
  }
);

export const deleteUser = createAsyncThunk(
  'User/deleteUser',
  async (_, { dispatch }) => {
    const { jwtToken, URL_ACCOUNT } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const user = await usersService.deleteUser(jwtToken, URL_ACCOUNT);

    return user;
  }
);

export const userSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {
    setUserProfile: (state, action) => {
      state.user = { ...action.payload };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(setUser.pending, (state) => {
        state.status.user = 'loading';
      })
      .addCase(setUser.fulfilled, (state, action) => {
        const user = action.payload;

        state.status.user = 'succeeded';
        state.user = user;
      })
      .addCase(setUser.rejected, (state, action) => {
        console.log('error fetching user: ', action.payload);

        state.status.user = 'rejected';
        state.error.user = action.error.message;
      })
      .addCase(updateUserName.pending, (state) => {
        state.status.nameChange = 'loading';
      })
      .addCase(updateUserName.fulfilled, (state, action) => {
        const user = action.payload;

        state.status.nameChange = 'succeeded';
        state.user = user;
      })
      .addCase(updateUserName.rejected, (state, action) => {
        console.log(action.error.message);

        state.status.nameChange = 'rejected';
        state.error.nameChange = action.error.message;
      })
      .addCase(cancelDeletionStatus.pending, (state) => {
        state.status.cancelDeletionStatus = 'loading';
      })
      .addCase(cancelDeletionStatus.fulfilled, (state, action) => {
        const user = action.payload;

        state.status.cancelDeletionStatus = 'succeeded';
        state.user = user;
      })
      .addCase(cancelDeletionStatus.rejected, (state, action) => {
        console.log(action.error.message);

        state.status.cancelDeletionStatus = 'rejected';
        state.error.cancelDeletionStatus = action.error.message;
      })
      .addCase(deleteUser.pending, (state) => {
        state.status.deleteUser = 'loading';
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        const user = action.payload;

        state.status.deleteUser = 'succeeded';
        state.user = user;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        console.log(action.error.message);

        state.status.deleteUser = 'rejected';
        state.error.deleteUser = action.error.message;
      });
  },
});

// selectors
export const selectUser = (state: RootState) => state.User.user;
export const getUserStatus = (state: RootState) => state.User.status.user;
export const getUserError = (state: RootState) => state.User.error;
export const getDeleteUserStatus = (state: RootState) =>
  state.User.status.deleteUser;

//actions
export const { setUserProfile } = userSlice.actions;

export default userSlice.reducer;
