import { Route, Routes as ReactRoutes } from 'react-router-dom';
import ProximamenteView from 'components/ProximamenteView/ProximamenteView';
// Types
import { IAccount } from '@trii/types/dist/Accounts';
// Components
import { Workspaces, WorkspaceAdd, WorkspaceEdit } from './features/Workspaces';
import MainLayout from './features/MainLayout';
import AuditLog from './features/auditLog/AuditLog';
import Profile from './features/Profile';
import Subscription from './features/Subscription/Subscription';
import Invoices from './features/Invoices/Invoices';
import InvoicesPay from 'features/InvoicesPay/InvoicesPay';
import Plans from 'features/Plans/Plans';
import { ResetPasswordView, SignInView, SignUpView, RequireAuth } from 'components';

const AppRouter = ({ user }: { user: IAccount }) => {
  const isAuthForPrivate = user ? true : false;
  const isAuthForPublic = user ? false : true;

  return (
    <ReactRoutes>
      <Route path="/" element={<MainLayout />}>
        <Route
          element={
            <RequireAuth
              redirectTo="signin"
              isPublic={false}
              isAuth={isAuthForPrivate}
            />
          }
        >
          <Route index element={<Profile />} />
        </Route>
        <Route
          element={
            <RequireAuth
              redirectTo="signin"
              isPublic={false}
              isAuth={isAuthForPrivate}
            />
          }
        >
          <Route path="account" element={<Profile />} />
        </Route>
        <Route
          element={
            <RequireAuth
              redirectTo="signin"
              isPublic={false}
              isAuth={isAuthForPrivate}
            />
          }
        >
          <Route path="workspaces" element={<Workspaces />} />
        </Route>
        <Route
          element={
            <RequireAuth
              redirectTo="signin"
              isPublic={false}
              isAuth={isAuthForPrivate}
            />
          }
        >
          <Route path="workspaces/Add" element={<WorkspaceAdd />} />
        </Route>
        <Route
          element={
            <RequireAuth
              redirectTo="signin"
              isPublic={false}
              isAuth={isAuthForPrivate}
            />
          }
        >
          <Route path="workspaces/:id" element={<WorkspaceEdit />} />
        </Route>
        <Route
          element={
            <RequireAuth
              redirectTo="signin"
              isPublic={false}
              isAuth={isAuthForPrivate}
            />
          }
        >
          <Route path="auditlog" element={<AuditLog />} />
        </Route>
        <Route
          element={
            <RequireAuth
              redirectTo="signin"
              isPublic={false}
              isAuth={isAuthForPrivate}
            />
          }
        >
          <Route path="subscribe" element={<Subscription />} />
        </Route>
        <Route
          element={
            <RequireAuth
              redirectTo="signin"
              isPublic={false}
              isAuth={isAuthForPrivate}
            />
          }
        >
          <Route path="invoices" element={<Invoices />} />
        </Route>
        <Route
          element={
            <RequireAuth
              redirectTo="signin"
              isPublic={false}
              isAuth={isAuthForPrivate}
            />
          }
        >
          <Route path="invoices/:id" element={<InvoicesPay />} />
        </Route>
        <Route
          element={
            <RequireAuth
              redirectTo="signin"
              isPublic={false}
              isAuth={isAuthForPrivate}
            />
          }
        >
          <Route path="subscribe/plans/:id" element={<Plans />} />
        </Route>
      </Route>
      <Route
        element={
          <RequireAuth redirectTo="/" isPublic={true} isAuth={isAuthForPublic} />
        }
      >
        <Route path="/signin" element={<SignInView />} />
      </Route>
      <Route
        element={
          <RequireAuth redirectTo="/" isPublic={true} isAuth={isAuthForPublic} />
        }
      >
        <Route path="/signupProximamente" element={<ProximamenteView />} />
      </Route>
      <Route
        element={
          <RequireAuth redirectTo="/" isPublic={true} isAuth={isAuthForPublic} />
        }
      >
        <Route path="/forgotpassword" element={<ResetPasswordView />} />
      </Route>
      <Route
        element={
          <RequireAuth redirectTo="/" isPublic={true} isAuth={isAuthForPublic} />
        }
      >
        <Route path="/signup" element={<SignUpView />} />
      </Route>
    </ReactRoutes>
  );
};

export default AppRouter;
