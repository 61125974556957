const modalListStyle = {
  modal: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: 'max-content',
    maxHeight: '80%',
    bgcolor: 'background.paper',
    boxShadow: '0 0 10px 0 rgb(33 43 54 / 10%)',
    borderRadius: '5px',
    p: 4,
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    height: '2rem',
    borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
  },
  container: {
      display: 'grid',
      gridTemplateColumns: '20% calc(80% - .5rem)',
      grirdTemplateRows: 'auto',
      columnGap: '.5rem',
      rowGap: '1rem',
      width: '100%',
      height: '40rem',
      overflowY: 'auto',
      overflowX: 'hidden',
      position: 'relative' as 'relative',
  }
}

export default modalListStyle