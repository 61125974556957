import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Typography } from '@mui/material';
import { green } from '@mui/material/colors';

export default function RequirementItem({ condition, text }) {
  return (
    <li>
      <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        {condition ? (
          <CheckCircleOutlineIcon style={{ color: green[500] }} fontSize="small" />
        ) : (
          <HighlightOffIcon fontSize="small" />
        )}
        <Typography color={'text.primary'} variant="caption">
          {text}
        </Typography>
      </div>
    </li>
  );
}
