// Translations
import { useTranslation } from "react-i18next";
// Components/ui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography
} from "@mui/material";
// Icons
import { Close } from "@mui/icons-material";
// Types
import { Plan } from "features/Plans/context/types/Plan";

interface ModalUpgradeProps {
  openModalUpgrade: boolean;
  handleCloseModalUpgrade: () => void;
  acceptNewPlan: () => void;
  actualPlan: Plan;
  newPlan: Plan;
}

const ModalUpgrade = ({
  openModalUpgrade,
  handleCloseModalUpgrade,
  acceptNewPlan,
  actualPlan,
  newPlan,
}: ModalUpgradeProps) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={openModalUpgrade}
      onClose={handleCloseModalUpgrade}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t('subscription.switchPlan')}
        <IconButton
          aria-label="close"
          size={"small"}
          onClick={handleCloseModalUpgrade}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close sx={{
            fontSize: '20px',
          }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          sx={{
            fontSize: "1rem",
            gap: '0.5rem',
            display: 'flex',
          }}
        >
          <Typography
            component="span"
          >
            {t('subscription.switchPlanDescription1')}
          </Typography>
          <Typography
            component="span"
            sx={{ fontWeight: "bold" }}
          >
            {t(actualPlan.title)}
          </Typography>
          <Typography
            component="span"
          >
            {t('subscription.switchPlanDescription2')}
          </Typography>
          <Typography
            sx={{ fontWeight: "bold" }}
            component="span"
          >
            {t(newPlan.title)}{t('subscription.switchPlanDescription3')}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleCloseModalUpgrade}
          color="primary"
        >
          {t('global.cancel')}
        </Button>
        <Button
          onClick={acceptNewPlan}
          variant="contained"
          color="success"
        >
          <Typography
            component="span"
            sx={{
              fontWeight: "bold",
              fontSize: "14px",
            }}>
            {t('subscription.switchTo')} {t(newPlan.title)}
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  )
};

export default ModalUpgrade;