import { useContext } from "react"
// Translations
import { useTranslation } from "react-i18next"
// Components/ui
import { Box } from "@mui/material"
// Components
import {
  CountrySelector,
  Input,
} from "./components"
// Context
import { plansContext } from "features/Plans/context/PlansProvider"
// Types
import { Country } from "features/Plans/context/types/Country"

const InvoiceData = () => {
  const {
    name,
    setName,
    country,
    setCountry,
    city,
    setCity,
    street,
    setStreet,
    streetNumber,
    setStreetNumber,
    postalCode,
    setPostalCode,
    cuit,
    setCuit,
  } = useContext(plansContext)
  const { t } = useTranslation()

  const handleChange = (
    setState: React.Dispatch<React.SetStateAction<string | number | Country>>
  ) => (value: string | number | Country) => {
    setState(value)
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '1rem',
      }}
    >
      <Input
        title={t('plans.invoiceData.name')}
        value={name}
        onChange={handleChange(setName)}
      />
      <CountrySelector
        title={t('plans.invoiceData.country')}
        value={country}
        onChange={handleChange(setCountry)}
      />
      <Input
        title={t('plans.invoiceData.city')}
        value={city}
        onChange={handleChange(setCity)}
      />
      <Input
        title={t('plans.invoiceData.street')}
        value={street}
        onChange={handleChange(setStreet)}
        isAddress
        addressTitle={t('plans.invoiceData.streetNumber')}
        addressValue={streetNumber}
        onChangeAddress={handleChange(setStreetNumber)}
      />
      <Input
        title={t('plans.invoiceData.postalCode')}
        value={postalCode}
        onChange={handleChange(setPostalCode)}
      />
      <Input
        title={t('plans.invoiceData.cuit')}
        value={cuit}
        onChange={handleChange(setCuit)}
      />
    </Box>
  )
}

export default InvoiceData