import {
  createSlice,
  createAsyncThunk,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit';
import { initRequestData } from 'ReduxToolkit/functions/initRequestData';
import InitRequestDataReturn from 'ReduxToolkit/types/InitRequestDataReturn';
// Service
import workspacesService from './workspaceService';
// Types
import { IworkspaceState } from './types/WorkspaceState';
import { PhotoWorkspace } from './types/PhotoWorkspace';
import { RootState } from 'ReduxToolkit/rootReducer';
import { RequestStatus } from 'ReduxToolkit/types/RequestStatus';

const initialState: IworkspaceState = {
  workspaces: null,
  workspace: null,
  status: {
    workspaces: 'idle',
    workspace: 'idle',
  },
};

export const getWorkspace = createAsyncThunk(
  'Workspaces/getWorkspace',
  async (workspaceId: string, { dispatch }) => {
    const { jwtToken, URL_ACCOUNT } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const workspace = await workspacesService.getWorkspace(
      jwtToken,
      URL_ACCOUNT,
      workspaceId
    );

    return workspace;
  }
);

export const getWorkspaces = createAsyncThunk(
  'Workspaces/getWorkspaces',
  async (_, { dispatch }) => {
    const { jwtToken, URL_ACCOUNT } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const workspaces = await workspacesService.getWorkspaces(jwtToken, URL_ACCOUNT);

    return workspaces;
  }
);

export const addWorkspace = createAsyncThunk(
  'Workspaces/addWorkspace',
  async (workspaceJson: string, { dispatch }) => {
    const { jwtToken, URL_ACCOUNT } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const newWorkspace = await workspacesService.postWorkspace(
      jwtToken,
      URL_ACCOUNT,
      workspaceJson
    );

    return newWorkspace;
  }
);

export const deleteCustomDomain = createAsyncThunk(
  'Workspaces/deleteCustomDomain',
  async (customDomainId: string, { dispatch }) => {
    const { jwtToken, URL_ACCOUNT } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const deletedCustomDomain = await workspacesService.deleteCustomDomain(
      jwtToken,
      URL_ACCOUNT,
      customDomainId
    );

    return deletedCustomDomain;
  }
);

export const deleteWorkspace = createAsyncThunk(
  'Workspaces/deleteWorkspace',
  async (workspaceId: string, { dispatch }) => {
    const { jwtToken, URL_ACCOUNT } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const deletedWorkspace = await workspacesService.deleteWorkspace(
      jwtToken,
      URL_ACCOUNT,
      workspaceId
    );

    return deletedWorkspace;
  }
);

export const putWorkspaceDomain = createAsyncThunk(
  'Workspaces/putWorkspaceDomain',
  async (updateData: any, { dispatch }) => {
    const { jwtToken, URL_ACCOUNT } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const { id, data } = updateData;
    const workspaceDomain = await workspacesService.putWorkspaceDomain(
      jwtToken,
      URL_ACCOUNT,
      data,
      id
    );

    return workspaceDomain;
  }
);

export const updateWorkspace = createAsyncThunk(
  'Workspaces/updateWorkspace',
  async (data: any, { dispatch }) => {
    const { jwtToken, URL_ACCOUNT } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const workspace = await workspacesService.updateWorkspace(
      jwtToken,
      URL_ACCOUNT,
      data
    );

    return workspace;
  }
);

export const postWorkspacePhoto = createAsyncThunk(
  'Workspaces/postWorkspacePhoto',
  async (data: PhotoWorkspace, { dispatch }) => {
    const { jwtToken, URL_ACCOUNT } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const { id, photo } = data;
    const workspace = await workspacesService.postWorkspacePhoto(
      jwtToken,
      URL_ACCOUNT,
      id,
      photo
    );

    return workspace;
  }
);

export const workspacesSlice = createSlice({
  name: 'workspaces',
  initialState,
  reducers: {
    setWorkspacesFetchStatus(state, action: PayloadAction<RequestStatus>) {
      state.status.workspaces = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getWorkspaces.pending, (state) => {
        state.status.workspaces = 'loading';
      })
      .addCase(getWorkspaces.fulfilled, (state, action) => {
        const workspaces = action.payload;

        state.status.workspaces = 'succeeded';
        state.workspaces = workspaces;
      })
      .addCase(getWorkspaces.rejected, (state, action) => {
        const error = action.error.message;
        console.log(error);

        state.status.workspaces = 'rejected';
      })
      .addCase(getWorkspace.pending, (state) => {
        state.status.workspace = 'loading';
      })
      .addCase(getWorkspace.fulfilled, (state, action) => {
        const workspace = action.payload;

        state.status.workspace = 'succeeded';
        state.workspace = workspace;
      })
      .addCase(getWorkspace.rejected, (state, action) => {
        const error = action.error.message;
        console.log(error);

        state.status.workspaces = 'rejected';
      })
      .addCase(addWorkspace.pending, (state) => {
        state.status.workspace = 'loading';
      })
      .addCase(addWorkspace.fulfilled, (state, action) => {
        const newWorkspace = action.payload;

        state.status.workspace = 'succeeded';
        state.workspaces = newWorkspace;
      })
      .addCase(addWorkspace.rejected, (state, action) => {
        const error = action.error.message;

        state.status.workspace = 'rejected';
      });
  },
});
// Actions
export const { setWorkspacesFetchStatus } = workspacesSlice.actions;
// Selectors
const workspaceState = (state: RootState) => state.Workspace;
export const selectWorkspaces = createSelector(
  workspaceState,
  (state) => state.workspaces
);
export const selectWorkspacesStatus = createSelector(
  workspaceState,
  (state) => state.status.workspaces
);
export const selectWorkspace = createSelector(
  workspaceState,
  (state) => state.workspace
);
export const selectWorkspaceStatus = createSelector(
  workspaceState,
  (state) => state.status.workspace
);
export const selectAddWorkspaceStatus = createSelector(
  workspaceState,
  (state) => state.status.workspace
);

export default workspacesSlice.reducer;
