/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import AppRouter from './AppRouter';
// Translation
import { useTranslation } from 'react-i18next';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
// Redux
import { useSelector } from 'react-redux';
import {
  selectSession,
  getSessionStatus,
  setSession,
} from 'ReduxToolkit/features/sessionSlice/sessionSlice';
import {
  setUser,
  getUserStatus,
  selectUser,
} from 'ReduxToolkit/features/userSlice/userSlice';
// Components/ui
import { LinearProgress, Box, Typography } from '@mui/material';

const App = () => {
  const dispatch = useAppDispatch();
  const session = useSelector(selectSession);
  const sessionStatus = useSelector(getSessionStatus);
  const userStatus = useSelector(getUserStatus);
  const user = useSelector(selectUser);
  const checkingSession =
    (sessionStatus === 'idle' && userStatus === 'idle') ||
    (sessionStatus === 'loading' && userStatus === 'idle');
  const checkingUser =
    (sessionStatus === 'succeeded' && userStatus === 'idle') ||
    (sessionStatus === 'succeeded' && userStatus === 'loading');
  const bothLoading = sessionStatus === 'loading' && userStatus === 'loading';
  const checkingCredentials = bothLoading || checkingUser || checkingSession;

  useEffect(() => {
    dispatch(setSession());
  }, [dispatch]);

  useEffect(() => {
    if (sessionStatus === 'succeeded' && userStatus === 'idle' && session) {
      dispatch(setUser());
    }
  }, [sessionStatus]);

  useEffect(() => {
    if (user && session) {
      const decodedURI = decodeURIComponent(window.location.search);
      const params = new URLSearchParams(decodedURI);
      const redirect = params.get('redirect');

      if (redirect) {
        // First check if redirection starts with http, if so redirect to that url
        if (redirect.startsWith('http')) {
          window.location.href = redirect;
        }
        else if(redirect.startsWith('/')) {
          window.location.href = 'https://agent.trii.app' + redirect;
        }
      }
    }
  }, [user, session]);

  if (checkingCredentials) return <PageLoading />;

  return <AppRouter user={user} />;
};

export default App;

const PageLoading = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Typography
        variant="h5"
        sx={{
          color: '#334155',
          marginBottom: 2,
        }}
      >
        {t('global.loading')}...
      </Typography>
      <LinearProgress sx={{ width: '20%' }} />
    </Box>
  );
};
