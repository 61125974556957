// Components/ui
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleContext } from 'style/styleProvider';
import { Date, StatusPill } from './components';
import PayButton from './components/PayButton/PayButton';
import { Box, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
// Types
import { IInvoice, InvoiceStatus } from '@trii/types/dist/Accounts';
import { getStatusColor } from './functions/getStatusColor';
import { useParams } from 'react-router-dom';

type Props = {
  data: IInvoice;
};

const Payment = ({ data }:Props) => {
  const { t } = useTranslation();
  const { mediaQuery, color, fontSize, shadow } =
    useContext(StyleContext);
  const params = useParams()
  const height = mediaQuery.xl ? '130px' : '160px';
  const statusColor = getStatusColor(data.status);
  const isPaying = params.id ? true : false
  const needsToBePaid = data.status ===  InvoiceStatus.OVERDUE || data.status ===  InvoiceStatus.SENT

  return (
    <Box
      className="fadein"
      display={'flex'}
      alignItems={'center'}
      justifyContent="space-between"
      borderRadius="3px"
      border={'1px solid #e6e6e6'}
      borderLeft={`8px solid ${statusColor}`}
      px={3}
      py={2}
      bgcolor={color.neutral[50]}
      color={color.slate[600]}
      height={height}
      boxShadow={shadow.md}
    >
      <Box display={'flex'} flexDirection="column" style={{ gap: '7px' }}>
        <StatusPill status={data.status} />
        <Typography fontSize={fontSize.xl}>${data.amount}</Typography>
      </Box>
      <Date text={t('subscription.period')} date={data.dueDate} />
      <Box display={'flex'} flexDirection="column" style={{ gap: '7px' }}>
        <Date text={t('subscription.expirationDate')} date={data.dueDate} />
        <Date text={t('subscription.paymentDate')} date={data.paymentDate} />
      </Box>
      <Box display={"flex"} gap={1}>
        <Button variant="outlined" size={"small"}>
          {t('global.download')}
        </Button> 
       {(needsToBePaid && !isPaying) && <PayButton invoiceId={data.id} />}
      </Box>
    </Box>
  );
}

export default Payment