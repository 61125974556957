import { useContext, useEffect, useState } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Router
import { useNavigate } from 'react-router-dom';
// Components/ui
import { Box, Button, Typography } from '@mui/material';
// Icons
import {
  CardMembershipOutlined
} from '@mui/icons-material';
// Context
import { StyleContext } from 'style/styleProvider';
// Types
import { ISubscription } from '@trii/types/dist/Accounts';
// Moment
import * as moment from 'moment';

const DATE_FORMAT = 'DD/MM/YYYY';

interface SubscriptionProps {
  subscriptionData: ISubscription;
}

const Subscription = ({
  subscriptionData
}: SubscriptionProps) => {
  const { fontSize, fontWeight, color, shadow } = useContext(StyleContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [remainingDays, setRemainingDays] = useState<number>(0);

  const redirectChangePlan = () => {
    navigate(`/subscribe/plans/${subscriptionData.id}`);
  }

  useEffect(() => {
    if (subscriptionData.isTrial) {
      const trialEndsAt = moment(subscriptionData.trialEndsAt);
      const today = moment();
      const daysRemaining = trialEndsAt.diff(today, 'days');
      setRemainingDays(daysRemaining);
    }
  }, [])

  return (
    <Box
      sx={{
        display: 'flex',
        bgcolor: color.neutral[50],
        boxShadow: shadow.md,
        borderRadius: 4,
        py: { md: '1.1rem', lg: '1.4rem' },
        px: { md: '2.1rem', lg: '3.5rem' },
        gridColumnGap: { md: '7rem', lg: '13rem' },
      }}
    >
      <Box width={"100%"}>
        {/* caja 1 */}
        <Box display="flex" justifyContent={"space-between"}>
          <Box display="flex" style={{ gap: '0.5rem' }} marginRight={5}>
            <CardMembershipOutlined
              fontSize={'medium'}
              sx={{ color: color.orange[400] }}
            />
            <Box style={{ gap: '0.5rem', display: 'flex', flexDirection: 'column' }}>
              <Box
                style={{
                  gap: '0.3rem',
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '0.7rem',
                }}
              >
                <Typography
                  fontWeight={fontWeight.semibold}
                  color={color.slate[900]}
                  fontSize={fontSize.lg}
                >
                  {subscriptionData.name}
                </Typography>
              </Box>
              <Box
                py={'0.4rem'}
                style={{
                  backgroundColor: subscriptionData.isTrial?  color.orange[100] : color.green[100],
                  border: '1px solid',
                  borderColor: subscriptionData.isTrial?  color.orange[200] : color.green[200],
                  borderRadius: '4px',
                  width:  '100px',
                }}
              >
                <Typography
                  textAlign={'center'}
                  color={subscriptionData.isTrial?  color.orange[500] : color.green[500]}
                  fontWeight={fontWeight.semibold}
                  fontSize={fontSize.sm}
                >
                  {
                    subscriptionData.isTrial ? (
                      t('subscription.inTrial')
                    ) : (
                      t('subscription.active')
                    )
                  }
                </Typography>
              </Box>
            </Box>
          </Box>
          {/* Current plan name */}
          <Box display={"flex"} flexDirection={"column"} alignItems={"flex-end"}>
            <Typography fontWeight={fontWeight.semibold} fontSize={fontSize.lg}>
              {
                subscriptionData.plan.usersType === 'BASIC' ? (
                  t('subscription.basic')
                ) : (
                  t('subscription.pro')
                )
              }
            </Typography>
            <Button sx={{ marginTop: 2 }} variant={"contained"} size={"small"} onClick={redirectChangePlan}>
              {t('subscription.changePlan')}
            </Button>
          </Box>
        </Box>
        {
          (subscriptionData.isTrial || true) && (
            <Box
              display="flex"
              sx={{
                marginTop: '30px',
                gap: '1rem',
              }}
            >
              <Typography variant={'caption'} fontWeight={fontWeight.semibold}>
                *{
                  remainingDays
                } - {t('subscription.freeTrialRemaining')}
              </Typography>
              <Typography variant={'caption'} >
                {t('subscription.trialEnds')} {moment(subscriptionData.trialEndsAt).format(DATE_FORMAT)}
              </Typography>
            </Box>
          )
        }
      </Box>
    </Box>
  );
}

export default Subscription;