// React Router
import { useParams } from "react-router-dom";
// Hooks
import { useAppDispatch } from "hooks/useAppDispatch";
// Types
import { createContext, useState } from "react";
import { PlansContext } from "./types/PlansContext";
// Types
import { Plan } from "./types/Plan";
import { Country } from "./types/Country";
// Slice
import { fetchSubscription } from "ReduxToolkit/features/subscriptionSlice/subscriptionSlice";

interface PlansProviderProps {
  children: React.ReactNode;
}

export const plansContext = createContext<PlansContext>({
  // Plans
  plans: [],
  setPlans: () => { },
  mode: '',
  setMode: () => { },
  // Invoice data
  name: '',
  setName: (name: string) => { },
  country: null,
  setCountry: (country: Country) => { },
  city: '',
  setCity: (city: string) => { },
  street: '',
  setStreet: (street: string) => { },
  streetNumber: null,
  setStreetNumber: (streetNumber: number) => { },
  postalCode: '',
  setPostalCode: (postalCode: string) => { },
  cuit: '',
  setCuit: (cuit: string) => { },
  // Other Products
  channelQuantity: 1,
  setChannelQuantity: (channelQuantity: number) => { },
  usersQuantity: 1,
  setUsersQuantity: (usersQuantity: number) => { },
  // General Funcions
  handleSave: () => { },
  getInfoPlan: () => { },
});

export const PlansProvider = ({
  children
}: PlansProviderProps) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [mode, setMode] = useState<string>('plans.planType');
  // Plans
  const [plans, setPlans] = useState<Plan[]>([]);
  // Invoice data
  const [name, setName] = useState<string>('');
  const [country, setCountry] = useState<Country>(null);
  const [city, setCity] = useState<string>('');
  const [street, setStreet] = useState<string>('');
  const [streetNumber, setStreetNumber] = useState<number>(null);
  const [postalCode, setPostalCode] = useState<string>('');
  const [cuit, setCuit] = useState<string>('');
  // Other Products
  const [channelQuantity, setChannelQuantity] = useState<number>(1);
  const [usersQuantity, setUsersQuantity] = useState<number>(1);

  // General Funcions
  const handleSave = () => {
    const data = {
      name,
      country,
      city,
      street,
      streetNumber,
      postalCode,
      cuit,
    }
    console.log('Save', data);
  }

  const getInfoPlan = async () => {
    await dispatch(fetchSubscription(id))
  }

  return (
    <plansContext.Provider
      value={{
        // Plans
        plans,
        setPlans,
        mode,
        setMode,
        // Invoice data
        name,
        setName,
        country,
        setCountry,
        city,
        setCity,
        street,
        setStreet,
        streetNumber,
        setStreetNumber,
        postalCode,
        setPostalCode,
        cuit,
        setCuit,
        // Other Products
        channelQuantity,
        setChannelQuantity,
        usersQuantity,
        setUsersQuantity,
        // General Funcions
        handleSave,
        getInfoPlan,
      }}>
      {children}
    </plansContext.Provider>
  );
}