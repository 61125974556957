/* eslint-disable import/no-anonymous-default-export */
import getRequestConfig from 'ReduxToolkit/functions/getRequestConfig';
import axios from 'axios';

const getUser = async (jwt: string, URL: string) => {
  const config = getRequestConfig.basic(jwt);

  const response = await axios.get(`${URL}/User`, config);
  return response.data;
};

const updateUser = async (jwt: string, URL: string, newUserJson: any) => {
  const config = getRequestConfig.contentTypeJson(jwt);

  const response = await axios.put(`${URL}/User`, newUserJson, config);
  return response.data;
};

const deleteUser = async (jwt: string, URL: string) => {
  const config = getRequestConfig.basic(jwt);

  const response = await axios.delete(`${URL}/User`, config);
  return response.data;
};

export default {
  getUser,
  updateUser,
  deleteUser,
};
