// Components/ui
import { Autocomplete, Box, TextField, Typography } from "@mui/material";
// Utils
import countries from "./utils/countries";
// Types
import { Country } from "features/Plans/context/types/Country";
import { useEffect } from "react";

interface CountrySelectorProps {
  title: string;
  onChange: (value: Country) => void;
  value: Country;
}

const CountrySelector = ({
  title,
  onChange,
  value,
}: CountrySelectorProps) => {
  useEffect(() => {
    const getCountry = countries.find((country) => country.value === "AR");
    onChange(getCountry as Country);
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '1rem',
        // borderBottom: '1px solid rgba(0,0,0,0.2)',
      }}
    >
      {/* <Typography
        variant="subtitle2"
        sx={{
          fontWeight: 700,
          width: '20%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        {title}
      </Typography> */}
      <Autocomplete
        size="small"
        fullWidth
        value={value}
        onChange={(event, newValue) => onChange(newValue)}
        options={countries}
        getOptionLabel={(option) => option.description}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            sx={{
              height: '100%',
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
            }}
            label={title}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
        sx={{
          width: '80%',
        }}
      />
    </Box>

  )
}

export default CountrySelector