import { createAsyncThunk } from '@reduxjs/toolkit';
import { setSession } from '../features/sessionSlice/sessionSlice';
import {
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoAccessToken,
} from 'amazon-cognito-identity-js';
// Types
import InitRequestDataReturn from '../types/InitRequestDataReturn';
import { RootState } from '../rootReducer';

interface CognitoUserSession {
  idToken?: CognitoIdToken;
  refreshToken?: CognitoRefreshToken;
  accessToken?: CognitoAccessToken;
  clockDrift?: number;
}

export const initRequestData = createAsyncThunk<
  InitRequestDataReturn,
  void,
  { state: RootState }
>('session/initRequest', async (_, { dispatch, getState }) => {
  const sessionRefresh: CognitoUserSession = (await dispatch(setSession())).payload;
  const { Space } = getState();
  const jwtToken = sessionRefresh.idToken.getJwtToken();
  const URL_ACCOUNT = Space.URL.ACCOUNT;
  const URL_USER = Space.URL.USER;

  return {
    jwtToken,
    URL_ACCOUNT,
    URL_USER,
  };
});
