// Redux
import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { initRequestData } from 'ReduxToolkit/functions/initRequestData';
import InitRequestDataReturn from 'ReduxToolkit/types/InitRequestDataReturn';
import { RootState } from 'ReduxToolkit/rootReducer';
// Servuce
import invoiceService from './invoiceService';
// Types
import { InvoiceState } from './types/InvoiceState';
import { IInvoice, IInvoiceBalance } from '@trii/types/dist/Accounts';
import { PayloadAction } from '@reduxjs/toolkit';
import { RequestStatus } from 'ReduxToolkit/types/RequestStatus';

const initialState: InvoiceState = {
  balance: null,
  invoices: [],
  status: {
    fetchInvoices: 'idle',
    fetchBalance: 'idle'
  }
};

export const getInvoices = createAsyncThunk< 
  IInvoice[],
  void,
  { state: RootState}>
  ('invoices/getInvoices',
  async (_, { dispatch }) => {
    const { jwtToken, URL_ACCOUNT } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    
    const invoices = await invoiceService.getInvoices(
      jwtToken,
      URL_ACCOUNT
    );
    return invoices;
  }
);

export const getBalance = createAsyncThunk<
IInvoiceBalance,
void,
{state: RootState}>
('invoices/getBalance',
  async (_, { dispatch }) => {
    const { jwtToken, URL_ACCOUNT } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    
    const balance = await invoiceService.getBalance(
      jwtToken,
      URL_ACCOUNT
    );
    return balance;
  }
);

export const invoiceSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    setInvoicesFetchStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.status.fetchInvoices = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getInvoices.pending, (state) => {
        state.status.fetchInvoices = 'loading';
      })
      .addCase(getInvoices.fulfilled, (state, action) => {
        const invoices = action.payload;
        state.invoices = invoices;
        state.status.fetchInvoices = 'succeeded';
      })
      .addCase(getBalance.pending, (state) => {
        state.status.fetchBalance = 'loading';
      })
      .addCase(getBalance.fulfilled, (state, action) => {
        const balance = action.payload;
        state.balance = balance;
        state.status.fetchBalance = 'succeeded';
      })
  },
});

export const {setInvoicesFetchStatus} = invoiceSlice.actions;
// Selectors
const invoiceState = (state: RootState) => state.Invoice;
export const invoiceListSelector = createSelector(
  invoiceState,
  (invoice) => invoice.invoices
)
export const selectInvoiceById = (id:string) =>
  createSelector(
    invoiceState,
    (state) => state.invoices.find((invoice) => invoice.id === id)
  );
export const invoiceStatusSelector = createSelector(
  invoiceState,
  (invoice) => invoice.status.fetchInvoices
)
export const selectBalance = createSelector(
  invoiceState, (state) => state.balance)

export default invoiceSlice.reducer;
