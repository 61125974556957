// Translations
import { useTranslation } from "react-i18next";
// Components/ui
import { Box, Button, Typography } from "@mui/material"
// Styles
import productCardStyles from "../../../ProductCard/styles";

interface Props {
  quantity: string;
  costPerMonth: string;
  costExeded: string;
  handleSelect: () => void;
}

const Information = ({
  quantity,
  costPerMonth,
  costExeded,
  handleSelect,
}: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Typography
        variant="body1"
        sx={{
          ...productCardStyles.information,
          bgcolor: (theme) => theme.palette.grey[300],
          color: (theme) => theme.palette.grey[800],
          fontWeight: 'bold',
          fontSize: 30,
        }}
      >
        {quantity}
      </Typography>
      <Box
        sx={{
          ...productCardStyles.information,
          bgcolor: (theme) => `${theme.palette.primary.light}30`,
          justifyContent: 'space-between',
        }}
      >
        <Typography
          variant="body1"
          fontWeight='bold'
          fontSize={30}
          color={(theme) => theme.palette.grey[600]}
        >
          US${costPerMonth}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            gap: '.5rem',
          }}
        >
          <Typography
            fontSize={14}
            variant="body1"
          >
            {t('plans.otherProducts.emailExededCost')}
          </Typography>
          <Typography
            variant="body1"
            fontWeight='bold'
            fontSize={14}
            color={(theme) => theme.palette.grey[600]}
          >
            US${costExeded}
          </Typography>
        </Box>
        <Button
          variant="contained"
          size="small"
          onClick={handleSelect}
        >
          {t('plans.otherProducts.select')}
        </Button>
      </Box>
    </>
  )
}

export default Information