import  { useContext } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Components
import { StyleContext } from 'style/styleProvider';
import { Box, Typography } from '@mui/material';
import { StatusIcon } from './components';
// Types
import { InvoiceStatus } from '@trii/types/dist/Accounts';
// Utils
import { getStatusColor } from '../../functions/getStatusColor';
import { getStatusName } from '../../functions/getStatusName';

interface Props {
  status: InvoiceStatus;
}

export default function StatusPill({ status }:Props) {
  const { t } = useTranslation();
  const { mediaQuery, fontSize, color, fontWeight } = useContext(StyleContext);
  const width = mediaQuery.md ? '140px' : '165px';
  const statusName = getStatusName(status)
  const statusColor = getStatusColor(status);

  return (
    <Box
      display={'flex'}
      fontWeight={fontWeight.medium}
      color={color.slate[800]}
      border={`2px solid ${statusColor}`}
      borderLeft={`31px solid ${statusColor}`}
      borderRadius="9999px"
      width={width}
    >
       <StatusIcon status={status} />
      <Typography fontSize={fontSize.sm}>{t(`invoices.status.${statusName}`)}</Typography>
    </Box>
  );
}
