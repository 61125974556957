import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  createSelector,
} from '@reduxjs/toolkit';
import { initRequestData } from '../../functions/initRequestData';
// Types
import { RootState } from '../../store';
import { ISpaceInfo } from '@trii/types/dist/Spaces';
import InitRequestDataReturn from 'ReduxToolkit/types/InitRequestDataReturn';
import { SpaceSliceState } from './types/SpaceSliceState';
import { RequestStatus } from 'ReduxToolkit/types/RequestStatus';
// Service
import spaceSliceService from './spaceSliceService';

const initialState: SpaceSliceState = {
  URL: {
    USER: 'https://agent-api.trii.app/api/v1/settings/user',
    ACCOUNT: 'https://account.trii.app/api/v1',
  },
  status: {
    fetch: 'idle',
  },
};

const spaceSlice = createSlice({
  name: 'space',
  initialState,
  reducers: {},
});

export default spaceSlice.reducer;
