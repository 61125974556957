import { useContext, useEffect, useState } from "react";
// Translations
import { useTranslation } from "react-i18next";
// Context
import { plansContext } from "features/Plans/context/PlansProvider";
// Components/ui
import {
  Box,
  Tab,
  Tabs,
} from "@mui/material";
// Components
import {
  ActionButtons,
  InvoiceData,
  OtherProducts,
  PlanType
} from "./components";

const PlanContainer = () => {
  const {
    setMode,
    getInfoPlan,
  } = useContext(plansContext);
  const { t } = useTranslation();
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        setMode('plans.planType');
        break;
      case 1:
        setMode('plans.otherProducts.title');
        break;
      case 2:
        setMode('plans.invoiceData.title');
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    getInfoPlan();
  }, [])

  return (
    <Box
      width={'100%'}
      height={'100%'}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        margin: 'auto',
        padding: 2
      }}
    >
      <ActionButtons />
      <Box
        width={'100%'}
        height={'calc(100% - 4rem)'}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          bgcolor: (theme) => theme.palette.background.paper,
          borderRadius: '4px',
          boxShadow: '0 0 10px 0 rgb(33 43 54 / 10%)',
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="disabled tabs example"
          sx={{
            borderBottom: '1px solid rgba(0,0,0,0.2)',
            height: '3rem',
          }}
        >
          <Tab label={t('plans.planType')} />
          <Tab label={t('plans.otherProducts.title')} />
          <Tab label={t('plans.invoiceData.title')} />
        </Tabs>
        <Box
          sx={{
            display: 'flex',
            height: 'calc(100% - 3rem)',
            overflow: 'auto'
          }}
        >
          {
            value === 0 && (
              <PlanType />
            )
          }
          {
            value === 1 && (
              <OtherProducts />
            )
          }
          {
            value === 2 && (
              <InvoiceData />
            )
          }
        </Box>
      </Box>
    </Box>
  );
}

export default PlanContainer;