import { useNavigate } from "react-router-dom"
// Translation
import { useTranslation } from "react-i18next"
// Components/ui
import { Button } from "@mui/material"

interface Props {
    invoiceId: string
}

const PayButton = ({ invoiceId }:Props) => {
    const navigate = useNavigate();
    const { t } = useTranslation()

    const handleNavigatePay = () => {
        navigate(`/invoices/${invoiceId}`);
    };
    
  return (
    <Button onClick={handleNavigatePay} variant="contained" size={"small"}>
        {t('invoices.pay')}
    </Button>
  )
}

export default PayButton