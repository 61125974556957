// Components/ui
import { Box, Button, Typography } from "@mui/material"
// Icons
import { OpenInNew } from "@mui/icons-material";

interface Props {
  showButton: boolean;
  handleNavigate: () => void;
  title: string;
  color: 'primary' | 'warning';
  showEndIcon?: boolean;
}

const NavigateButton = ({
  showButton,
  handleNavigate,
  title,
  color,
  showEndIcon = true,
}: Props) => (
  <Box
    sx={{
      display: showButton ? 'flex' : 'none',
      position: 'absolute',
      top: 0,
      zIndex: 1,
      width: 'calc(100% - 3rem)',
      justifyContent: 'center',
      alignItems: 'center',
      animation: 'display 0.5s ease-in-out',
      '@keyframes display': {
        '0%': {
          opacity: '0',
        },
        '100%': {
          opacity: '1',
        },
      },
    }}
  >
    <Button
      color={color}
      onClick={handleNavigate}
      variant='contained'
      size='small'
      endIcon={
        showEndIcon &&
        <OpenInNew
          sx={{
            fontSize: '1rem',
          }}
        />
      }
    >
      <Typography
        variant='caption'
        sx={{
          width: 'max-content',
          height: '100%',
          textAlign: 'center',
          display: 'flex',
        }}
      >
        {title}
      </Typography>
    </Button>
  </Box>
)

export default NavigateButton