export const invoicesPayStyle = {
    container : {
        display:'flex',
        flexDirection:'column',
        height:'85vh',
        width:'100%',
        // boxShadow:1,
        // bgcolor:"#f3f4f6",
        className:'fadeinup',
        borderRadius:4,
        p:4,
        gap:4
    }
}