import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// Components/ui
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Profile.css';
import {
  ResetPasswordPanel,
  PersonalInformationPanel,
  EraseAccountPanel,
  SelectLanguagePanel,
} from './components';
import { Container } from '@mui/material';

toast.configure();

const Profile = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname === '/') {
      navigate('account');
    }
  }, []);

  return (
    <div className="border-faded border-0">
      <Container maxWidth="md">
        <PersonalInformationPanel />
        <ResetPasswordPanel />
        <SelectLanguagePanel />

        <EraseAccountPanel />
      </Container>
    </div>
  );
};

export default Profile;
