import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import subdomainService from './subdomainService';
import { RootState } from 'ReduxToolkit/rootReducer';
import { initRequestData } from 'ReduxToolkit/functions/initRequestData';
import InitRequestDataReturn from 'ReduxToolkit/types/InitRequestDataReturn';

const initialState = {
  subdomain: '',
  availability: null,
  status: 'idle',
  error: null,
};

export const getSubdomainAvailability = createAsyncThunk(
  'Subdomain/checkSubdomainAvailability',
  async (subdomainObj: any, { dispatch }) => {
    const { jwtToken, URL_ACCOUNT } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const { subdomainJson, subdomainName } = subdomainObj;
    const { available } = await subdomainService.getSubdomainAvailability(
      jwtToken,
      URL_ACCOUNT,
      subdomainJson
    );

    return { subdomainName, available };
  }
);

const subdomainSlice = createSlice({
  name: 'subdomain',
  initialState,
  reducers: {
    setStatus(state, action) {
      state.status = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getSubdomainAvailability.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getSubdomainAvailability.fulfilled, (state, action) => {
        const subdomainObj = action.payload;

        state.status = 'succeeded';
        state.subdomain = subdomainObj.subdomainName;
        state.availability = subdomainObj.available;
      })
      .addCase(getSubdomainAvailability.rejected, (state, action) => {
        state.status = 'failed';

        console.log(action.error);
      });
  },
});

const subdomainState = (state: RootState) => state.Subdomain;
export const selectSubdomain = createSelector(
  subdomainState,
  (state) => state.subdomain
)
export const selectSubdomainAvailability = createSelector(
  subdomainState,
  (state) => state.availability
)
export const selectSubdomainStatus = createSelector(
  subdomainState,
  (state) => state.status
)


export default subdomainSlice.reducer;
