// Translations
import { useTranslation } from "react-i18next";
// Components/ui
import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from "@mui/material";

interface PlanDurationProps {
  pricingOption: string;
  handleClick: (
    event: React.SyntheticEvent,
    newPricingOption: string
  ) => void;
}

const PlanDuration = ({
  pricingOption,
  handleClick,
}: PlanDurationProps) => {
  const { t } = useTranslation();
  return (
    <Box
      display={'flex'}
      justifyContent={'center'}
    >
      <ToggleButtonGroup
        value={pricingOption}
        exclusive
        onChange={handleClick}
      >
        <ToggleButton
          value="monthly"
          size='small'
          sx={{
            backgroundColor: (theme) => (
              pricingOption === 'monthly'
                ? `${theme.palette.primary.light} !important`
                : 'transparent'
            ),
            width: '10rem',
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 700,
              color:
                pricingOption !== 'annual' ? (
                  'common.white') : (
                  'text.primary'
                ),
            }}
          >
            {t('pricing.monthly')}
          </Typography>
        </ToggleButton>
        <ToggleButton
          value="annual"
          size='small'
          sx={{
            backgroundColor: (theme) => (
              pricingOption === 'annual'
                ? `${theme.palette.primary.light} !important`
                : 'transparent'
            ),
            width: '10rem',
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 700,
              color:
                pricingOption === 'annual' ? (
                  'common.white') : (
                  'text.primary'
                ),
            }}
          >
            {t('pricing.annual')}
          </Typography>
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  )
};

export default PlanDuration