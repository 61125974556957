/* eslint-disable import/no-anonymous-default-export */
import getRequestConfig from 'ReduxToolkit/functions/getRequestConfig';
import axios from 'axios';

//GET
const fetchSubscription = async (
  jwt: string,
  URL: string,
  id: string
) => {
  const requestConfig = getRequestConfig.contentTypeJson(jwt);

  const response = await axios.get(
    `${URL}/Subscriptions/${id}`,
    requestConfig
  );
  return response.data;
};

const fetchSubscriptionList = async (
  jwt: string,
  URL: string,
) => {
  const requestConfig = getRequestConfig.contentTypeJson(jwt);

  const response = await axios.get(
    `${URL}/Subscriptions`,
    requestConfig
  );
  return response.data;
};

//PUT
const updateSubscription = async (
  jwt: string,
  URL: string,
  data: any
) => {
  const requestConfig = getRequestConfig.contentTypeJson(jwt);
  const dataJSON = JSON.stringify(data);

  const response = await axios.put(URL, dataJSON, requestConfig);
  return response.data;
};



export default {
  fetchSubscription,
  fetchSubscriptionList,
  updateSubscription,
};
