import { createContext } from 'react';
import {
  CognitoUser,
  AuthenticationDetails,
  CookieStorage,
} from 'amazon-cognito-identity-js';
// Redux
import { selectPool } from 'ReduxToolkit/features/poolSlice/poolSlice';
import { useSelector } from 'react-redux';
import { ICognitoUserData } from 'amazon-cognito-identity-js';

export const AccountContext = createContext({});

const AccountProvider = ({ children }) => {
  const pool = useSelector(selectPool);

  const setUser = (Username: string) => {
    console.log('CookieStorage. 1');

    const cognitoUser: ICognitoUserData = {
      Username,
      Pool: pool,
      Storage: new CookieStorage({
        secure: true,
        sameSite: 'none',
        domain: window.location.host.split(':')[0].replace('account.', ''),
      }),
    };
    return new CognitoUser(cognitoUser);
  };

  const authenticate = async (Username, Password) => {
    return await new Promise((resolve, reject) => {
      const user = setUser(Username);

      const authDetails = new AuthenticationDetails({
        Username,
        Password,
      });

      user.authenticateUser(authDetails, {
        onSuccess: (result) => {
          console.log('onSuccess: ', result);
          resolve(result);
        },
        onFailure: (err) => {
          console.error('onFailure: ', err);
          reject(err);
        },
        newPasswordRequired: (result) => {
          console.log('newPasswordRequired: ', result);
          resolve(result);
        },
      });
    });
  };

  const resendVerificationCode = (email) => {
    const user = setUser(email);

    user.resendConfirmationCode((err, result) => {
      if (err) {
        console.log(err);
      } else {
        console.log('Resend verification code Success: ' + JSON.stringify(result));
      }
    });
  };

  return (
    <AccountContext.Provider
      value={{
        authenticate,
        setUser,
        resendVerificationCode,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export default AccountProvider;
