import { useContext, useEffect } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  getBalance,
  selectBalance,
} from 'ReduxToolkit/features/invoiceSlice/invoiceSlice';
import { useSelector } from 'react-redux';
// Components/ui
import { StyleContext } from 'style/styleProvider';
import { Box, Typography } from '@mui/material';
import StatusBar from './components';

export default function Header() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const balance = useSelector(selectBalance);
  const { fontSize, color } = useContext(StyleContext);

  useEffect(() => {
    dispatch(getBalance());
  }, [dispatch]);

  return (
    <Box height={'20%'}>
      <Box
        display={'flex'}
        borderBottom="1px solid #e5e7eb"
        alignItems="center"
        px={3}
        py={2}
        bgcolor={color.neutral[50]}
        borderRadius={4}
      >
        <Typography color={color.slate[800]} fontSize={fontSize.md}>
          {t('subscription.amountToBePaid')}
        </Typography>
      </Box>
      {balance && <StatusBar balance={balance} />}
    </Box>
  );
}
