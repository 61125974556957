import { useState } from 'react';
// Components/ui
import { Box } from '@mui/material';
import { payProcesorStyle } from './style';
import { MethodOptions, MercadoPagoTabContent } from './components';
import { TabPanel } from './layout';
// Types
import { SyntheticEvent } from 'react';

const PayProcesor = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={payProcesorStyle.container}>
      <MethodOptions handleChange={handleChange} value={value} />
      <TabPanel value={value} index={0}>
        <MercadoPagoTabContent />
      </TabPanel>
      <TabPanel value={value} index={1}>
        Card
      </TabPanel>
    </Box>
  );
};

export default PayProcesor;
