import { useContext } from 'react';
import { StyleContext } from 'style/styleProvider';
import {Box,Typography} from '@mui/material';
// Utils
import { transformDate } from 'functions/transformDate';

type Props = {
  date:Date
  text:string
}

export default function Date({ text, date }:Props) {
  const { fontSize, fontWeight } = useContext(StyleContext);

  return (
    <Box textAlign={'center'}>
      <Typography fontWeight={fontWeight.semibold} fontSize={fontSize.sm}>
        {text}
      </Typography>
      <Typography fontSize={fontSize.xs}>{transformDate(date)}</Typography>
    </Box>
  );
}
