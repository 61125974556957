import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useSelector } from 'react-redux';
import {
  getInvoices,
  selectInvoiceById,
} from 'ReduxToolkit/features/invoiceSlice/invoiceSlice';
// Components/ui
import { Box } from '@mui/material';
import { invoicesPayStyle } from './style';
import { Payment } from 'components';
import { PayProcesor } from './components';

const InvoicesPay = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const invoice = useSelector(selectInvoiceById(id));

  useEffect(() => {
    dispatch(getInvoices());
  }, [dispatch]);

  return (
    <Box sx={invoicesPayStyle.container}>
      {invoice && (
        <>
          <Payment data={invoice} />
          <PayProcesor />
        </>
      )}
    </Box>
  );
};

export default InvoicesPay;
