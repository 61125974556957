import { useState, useContext } from 'react';
// Components/ui
import { TabNavbar, InvoicesTab } from './components';
import { StyleContext } from '../../style/styleProvider';
import { Box } from '@mui/material';

export default function Invoices() {
  const [value, setValue] = useState(0);
  const { color, shadow } = useContext(StyleContext);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      height={'85vh'}
      width={'100%'}
      className={'fadeinup'}
    >
      {/* <TabNavbar value={value} handleChange={handleChange} /> */}
      <InvoicesTab value={value} />
    </Box>
  );
}
