// Translation
import { useTranslation } from "react-i18next"
// Components/ui
import { Box, Tabs, Tab } from "@mui/material"
import { methodOptionsStyle } from "./style"
// Types
import { SyntheticEvent } from "react"
interface Props {
  handleChange: (event: SyntheticEvent, newValue: number) => void
  value: number
}

const MethodOptions = ({handleChange, value}:Props) => {
  const {t} = useTranslation()

  function a11yProps(index: number) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`
    };
  }

  return (
    <Box sx={methodOptionsStyle.container}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        sx={{ borderRight: 1, borderColor: "divider" }}
      >
        <Tab label="Mercado Pago" {...a11yProps(0)} />
        <Tab label={t("global.creditCard")} {...a11yProps(1)} />
      </Tabs>
    </Box>
  )
}

export default MethodOptions