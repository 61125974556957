import { useContext } from "react";
// Redux
import { useSelector } from "react-redux";
// React Router
import { useNavigate } from "react-router-dom";
// Translations
import { useTranslation } from "react-i18next";
// Context
import { plansContext } from "features/Plans/context/PlansProvider";
import { StyleContext } from 'style/styleProvider';
// Components/ui
import {
  Box,
  Button,
  IconButton,
  Typography
} from "@mui/material"
// Icons
import {
  ArrowBack,
  Edit,
  ShoppingCart
} from "@mui/icons-material"
// Slice
import { subscriptionSelectorsById } from "ReduxToolkit/features/subscriptionSlice/subscriptionSlice";

const ActionButtons = () => {
  const {
    handleSave,
    mode,
  } = useContext(plansContext);
  const { fontSize } = useContext(StyleContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const subscription = useSelector(subscriptionSelectorsById);

  const backToSubscribeList = () => {
    navigate('/subscribe');
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '3rem',
        display: 'flex',
        alignItems: 'center',
        bgcolor: (theme) => theme.palette.background.paper,
        boxShadow: '0 0 10px 0 rgb(33 43 54 / 10%)',
        justifyContent: 'space-between',
        p: 1,
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        <IconButton size="small" onClick={backToSubscribeList}>
          <ArrowBack sx={{
            fontSize: '20px',
          }} />
        </IconButton>
        <Typography fontWeight={500} fontSize={fontSize.sm}>
          {subscription?.name}
        </Typography>
      </Box>
      {
        mode !== 'plans.planType' && (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              padding: '1rem',
            }}
          >
            {
              mode === 'plans.invoiceData.title' && (
                <Button
                  variant="outlined"
                  startIcon={<Edit />}
                  size="small"
                  onClick={handleSave}
                >
                  <Typography fontWeight={500} fontSize={fontSize.sm}>
                    {t('global.save')}
                  </Typography>
                </Button>
              )
            }
            {
              mode === 'plans.otherProducts.title' && (
                <IconButton>
                  <ShoppingCart />
                </IconButton>
              )
            }
          </Box>
        )
      }
    </Box>
  )
}

export default ActionButtons;