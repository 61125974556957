import { useState } from "react";
// Translations
import { useTranslation } from "react-i18next";
// Components/ui
import { Box, Button, IconButton, Modal, TextField, Typography } from "@mui/material"
// Styles
import modalListStyles from "./styles";
import { Close } from "@mui/icons-material";

interface Props {
  open: boolean;
  onClose: () => void;
  channelTitle: string;
  setState: React.Dispatch<React.SetStateAction<number>>;
  value: number;
}

const ModalChannel = ({
  open,
  onClose,
  channelTitle,
  setState,
  value,
}: Props) => {
  const { t } = useTranslation();
  const [isError, setIsError] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value) {
      const number = parseInt(value);
      if (!isNaN(number) && channelTitle === 'plans.otherProducts.channels') {
        setIsError((number < 1 || number > 50))
      } else {
        setIsError(number < 1)
      }
      if (channelTitle === 'plans.otherProducts.channels') {
        setState(number > 0 && number <= 50 ? number : 1);
      } else {
        setState(number > 0 ? number : 1);
      }
    } else {
      setState(null);
    }
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box
        sx={modalListStyles.modal}
      >
        <Box
          sx={modalListStyles.header}
        >
          <IconButton
            onClick={onClose}
          >
            <Close />
          </IconButton>
        </Box>
        <Box
          sx={modalListStyles.container}
        >
          <Typography
            variant="body1"
          >
            {t('plans.otherProducts.channelsQuantity', { channel: t(channelTitle) })}
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            type="number"
            value={value}
            onChange={handleChange}
            error={isError}
            helperText={isError && t('plans.otherProducts.channelsQuantityError')}
          />
        </Box>
        <Box
          sx={modalListStyles.footer}
        >
          <Button
            variant="outlined"
            onClick={onClose}
            size="small"
          >
            {t('global.cancel')}
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={onClose}
          >
            {t('plans.otherProducts.confirm')}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default ModalChannel