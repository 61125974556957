import {
  createSlice,
  createSelector,
  createAsyncThunk,
  PayloadAction,
} from '@reduxjs/toolkit';
import { initRequestData } from '../../functions/initRequestData';
// Types
import { RootState } from '../../store';
import { ISpaceInfo } from '@trii/types/dist/Spaces';
import { SpacesSliceState } from './types/SpacesSliceState';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
// Service
import spacesSliceService from './spacesSliceService';

const initialState: SpacesSliceState = {
  spaces: [],
  status: {
    fetch: 'idle',
    join: 'idle',
  },
};

export const getSpaces = createAsyncThunk(
  'space/getSpaces',
  async (_, { dispatch }) => {
    const { jwtToken } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await spacesSliceService.fetchSpaces(jwtToken);

    return response;
  }
);

const spacesSlice = createSlice({
  name: 'spaces',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSpaces.pending, (state) => {
      state.status.fetch = 'loading';
    })
      .addCase(
        getSpaces.fulfilled,
        (state, action: PayloadAction<ISpaceInfo[]>) => {
          state.status.fetch = 'succeeded';
          console.log('getSpaces succeeded: ', action.payload)
          state.spaces = action.payload;
        })
      .addCase(joinSpace.pending, (state) => {
        state.status.join = 'loading';
      })
      .addCase(
        joinSpace.fulfilled,
        (state) => {
          state.status.join = 'succeeded';
        }
      );
  },
});

export const joinSpace = createAsyncThunk(
  'space/joinSpace',
  async (spaceId: string, { dispatch }) => {
    const { jwtToken } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await spacesSliceService.joinSpace(jwtToken, spaceId);

    return response;
  }
);

const selectSpacesState = (state: RootState) => state.Spaces;
export const selectSpaces = createSelector(
  selectSpacesState,
  (state) => state.spaces
);
export const selectSpacesFetchStatus = createSelector(
  selectSpacesState,
  (state) => state.status.fetch
);
export const selectSpacesJoinStatus = createSelector(
  selectSpacesState,
  (state) => state.status.join
);

export default spacesSlice.reducer;
