import { useState } from "react";
// Components/ui
import { Box, TextField, Typography } from "@mui/material";

interface InputProps {
  title: string;
  value: string;
  onChange: (event: string | number) => void;
  isAddress?: boolean;
  addressTitle?: string;
  addressValue?: number;
  onChangeAddress?: (event: string | number) => void;
}

const Input = ({
  title,
  value,
  onChange,
  isAddress = false,
  addressTitle,
  addressValue,
  onChangeAddress,
}: InputProps) => {
  const [addressError, setAddressError] = useState(false);

  const handleChangeAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    const numberRegex = /^[0-9\b]+$/;
    if (e.target.value === '' || numberRegex.test(e.target.value)) {
      setAddressError(false);
      onChangeAddress && onChangeAddress(e.target.value);
    } else {
      setAddressError(true);
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10%',
        // borderBottom: '1px solid rgba(0,0,0,0.2)',
      }}
    >
      {/* <Typography
        variant="subtitle2"
        sx={{
          fontWeight: 700,
          width: '20%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        {title}
      </Typography> */}
      <TextField
        variant="outlined"
        size="small"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        label={title}

        fullWidth
        sx={{
          width: !isAddress ? '80%' : '35%',
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
      {
        isAddress && (
          <Box
            sx={{
              width: '35%',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '1rem',
            }}
          >
            {/* <Typography
              variant="subtitle2"
              sx={{
                fontWeight: 700,
                width: '20%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
            >
              {addressTitle}
            </Typography> */}
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              label={addressTitle}
              value={addressValue}
              onChange={handleChangeAddress}
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'center',
              }}            
              InputLabelProps={{
                shrink: true,
              }}
              error={addressError}
            />
          </Box>
        )
      }
    </Box>
  )
};

export default Input;