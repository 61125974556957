import { useContext, useEffect, useState } from 'react';
//components/ui
import {
  Avatar,
  Box,
  Chip,
  Fade,
  Stack,
  Typography,
} from '@mui/material';
// Redux
import { useSelector } from 'react-redux';
// Translations
import { useTranslation } from 'react-i18next';
// Types
import { Spaces } from '@trii/types';
import { AccessLevel } from '@trii/types/dist/Spaces';
// Context
import { StyleContext } from 'style/styleProvider';
// Components
import { NavigateButton } from './components';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
// Slice
import { joinSpace } from 'ReduxToolkit/features/spacesSlice/spacesSlice';
import { selectUser } from 'ReduxToolkit/features/userSlice/userSlice';
import { getWorkspaces } from 'ReduxToolkit/features/workspace/workspaceSlice';
// Style
import { StyledBadge } from './style/StyledBadge';

const Workspace = ({ workspace, isMobile }: WorkspaceProps) => {
  const currentUser = useSelector(selectUser);
  const dispatch = useAppDispatch();
  const {
    fontSize,
    fontWeight,
    color,
    shadow
  } = useContext(StyleContext);
  const [show, setShow] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [userType, setUserType] = useState<string>('');
  const { t } = useTranslation()

  const handleShowButton = () => {
    setShowButton(true);
  }

  const handleHideButton = () => {
    setShowButton(false);
  }

  const handleNavigateSpace = () => {
    window.open(`https://agent.trii.app/?spaceId=${workspace.id}`, '_blank');
  }

  const getUserType = (type: AccessLevel) => {
    if (!workspace.joinTimeStamp) {
      return t('workSpaces.pending').toUpperCase();
    }
    const text = Object.keys(AccessLevel).find(key => AccessLevel[key] === type);
    const filterText = text?.split('_')?.join(' ');
    return filterText;
  }

  const handleJoin = async (spaceId: string) => {
    const response = await dispatch(joinSpace(spaceId));
    console.log('response', response)
    if (response.meta.requestStatus === 'fulfilled') {
      await dispatch(getWorkspaces());
    }
  }

  useEffect(() => {
    setShow(true);
  }, []);

  useEffect(() => {
    if (currentUser) {
      const currentUserType = getUserType(workspace.accessLevel);
      setUserType(currentUserType);
    }
  }, [currentUser, workspace])

  return (
    <Fade style={{ transitionDuration: '750ms' }} in={show}>
      <Box
        className="px-4 py-2"
        sx={{
          width: isMobile ? '100vw' : 'auto',
          position: 'relative'
        }}
        onMouseEnter={handleShowButton}
        onMouseLeave={handleHideButton}
      >
        {
          workspace?.joinTimeStamp ? (
            <NavigateButton
              color='primary'
              showButton={showButton}
              handleNavigate={handleNavigateSpace}
              title={t('workSpaces.openLink')}
            />
          ) : (
            <NavigateButton
              color='warning'
              showButton={showButton}
              handleNavigate={() => handleJoin(workspace.id)}
              title={t('workSpaces.joinSpace')}
              showEndIcon={false}
            />
          )
        }
        <Box
          className="panel-inner my-0"
          display={'flex'}
          bgcolor={color.neutral[50]}
          boxShadow={shadow.md}
          borderRadius={1}
          px={{ md: '1.5rem', lg: '1.5rem' }}
        >
          <Box
            className={
              isMobile
                ? 'd-flex align-items-center p-3 w-100'
                : 'd-flex justify-content-between align-items-center p-3 w-100'
            }
          >
            {
              workspace?.joinTimeStamp ? (
                <Avatar
                  alt={workspace.name}
                  src={
                    workspace.imageUrl !== ''
                      ? workspace.imageUrl
                      : '/img/buildingDefault.png'
                  } sx={{ width: 64, height: 64 }}
                />
              ) : (
                <Stack
                  direction={'row'}
                  spacing={2}
                >
                  <StyledBadge
                    overlap='circular'
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    variant="dot"
                  >
                    <Avatar
                      alt={workspace.name}
                      src={
                        workspace.imageUrl !== ''
                          ? workspace.imageUrl
                          : '/img/buildingDefault.png'
                      } sx={{ width: 64, height: 64 }}
                    />
                  </StyledBadge>
                </Stack>
              )
            }
            {!isMobile ? (
              <>
                <Box className="d-inline-block ml-3 mr-auto pr-4">
                  <Box display={"flex"} alignItems={"center"}>
                    <Typography
                      fontWeight={fontWeight.semibold}
                      fontSize={fontSize.sm}
                      mr={2}
                    >
                      {workspace.name}
                    </Typography>
                    <Chip
                      label={userType}
                      sx={{
                        borderRadius: '5px',
                        width: 'fit-content !important',
                      }}
                      size="small"
                      color={!workspace.joinTimeStamp ? "warning" : "success"}
                    />
                  </Box>
                  <br></br>
                  <Typography
                    fontWeight={fontWeight.normal}
                    fontSize={fontSize.sm}
                  >
                    {workspace.domain}
                  </Typography>
                </Box>
                <Chip
                  label={Spaces.Status[workspace.status]}
                  // color={item.status === 3 ? 'success' : 'warning'}
                  sx={{ borderRadius: '5px' }}
                  size="small"
                />
              </>
            ) : (
              <>
                <Box className={'d-flex flex-column pl-3 align-items-center'}>
                  <Box className="d-inline-block ml-3 mr-auto pr-4">
                    <Box>

                      <Typography
                        fontWeight={fontWeight.semibold}
                        fontSize={fontSize.sm}
                      >
                        {workspace.name}
                      </Typography>
                      <Chip label="chip" sx={{
                        borderRadius: '5px',
                        width: 'fit-content !important',
                      }}
                        size="small"></Chip>
                    </Box>
                    <br></br>

                    <Typography
                      fontWeight={fontWeight.normal}
                      fontSize={fontSize.sm}
                    >
                      {workspace.domain}
                    </Typography>
                  </Box>
                  <Chip
                    label={Spaces.Status[workspace.status]}
                    // color={item.category === 'usuario' ? 'info' : 'success'}
                    sx={{
                      borderRadius: '5px',
                      width: 'fit-content !important',
                    }}
                    size="small"
                    className={'mt-3'}
                  />
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Fade >
  );
};

interface WorkspaceProps {
  index: number;
  workspace: Spaces.ISpaceInfo;
  isMobile: boolean;
}

export default Workspace;
