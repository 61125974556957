// Context
import { PlansProvider } from "./context/PlansProvider"
// Components
import { PlansContainer } from "./components"

const Plans = () => (
  <PlansProvider>
    <PlansContainer />
  </PlansProvider>
)

export default Plans