// Types
import { InvoiceStatus } from "@trii/types/dist/Accounts";

export const getStatusName = (status:InvoiceStatus) => {
    switch (status) {
        case InvoiceStatus.DRAFT:
            return "draft"
        case InvoiceStatus.SENT:
            return "pending"
        case InvoiceStatus.PAID:
            return "paid"
        case InvoiceStatus.OVERDUE:
            return "overdue"
        case InvoiceStatus.VOID:
            return "void"
        case InvoiceStatus.UNCOLLECTIBLE:
            return "uncollectible"
    }
}
