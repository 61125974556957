const productCardStyle = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: '100%',
    boxShadow: '0 0 10px 0 rgb(33 43 54 / 10%)',
    p: 1,
  },
  header: {
    width: '100%',
    height: '3rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
    p: 1,
    backgroundColor: 'background.paper',
    gap: '.5rem',
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: '10rem calc(100% - 10rem)',
    gridGap: '.5rem',
    padding: '1rem',
  },
  information: {
    width: '100%',
    height: '100%',
    color: 'text.disabled',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    p: '.5rem',
  },
}

export default productCardStyle