// Translations
import { useTranslation } from "react-i18next";
// Components/ui
import { Box, IconButton, Modal, Typography } from "@mui/material"
// Styles
import modalListStyles from "./styles";
import productCardStyles from "../ProductCard/styles";
// Utils
import { mockList } from "./utils/mockList";
import { Information } from "./components";
import { Close } from "@mui/icons-material";

interface Props {
  open: boolean;
  onClose: () => void;
}

const ModalList = ({
  open,
  onClose,
}: Props) => {
  const { t } = useTranslation();

  const handleSelect = () => {
    onClose();
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box
        sx={modalListStyles.modal}
      >
        <Box
          sx={modalListStyles.header}
        >
          <IconButton
            onClick={onClose}
          >
            <Close />
          </IconButton>
        </Box>
        <Box
          sx={modalListStyles.container}
        >
          <Typography
            variant="h5"
            sx={{
              ...productCardStyles.header,
              fontSize: 20,
              fontWeight: 'bold',
              color: (theme) => theme.palette.grey[800],
              position: 'sticky',
              top: 0,
            }}
          >
            {t('plans.otherProducts.emailQuantity')}
          </Typography>
          <Typography
            variant="h5"
            sx={{
              ...productCardStyles.header,
              fontSize: 20,
              fontWeight: 'bold',
              color: (theme) => theme.palette.grey[800],
              position: 'sticky',
              top: 0,
            }}
          >
            {t('plans.otherProducts.costPerMonth')}
          </Typography>
          {
            mockList.map((item) => (
              <Information
                key={item.id}
                quantity={item.quantity}
                costPerMonth={item.costPerMonth}
                costExeded={item.costExeded}
                handleSelect={handleSelect}
              />
            ))
          }
        </Box>
      </Box>
    </Modal>
  )
}

export default ModalList